const query = `
query ($app_id: String!) {
  app(id: $app_id) {
    active_users {
      slice {
        semantic_time {
          type
          all_days {
            type
            morning {
              type
              semantic_time
              days
              start
              end
            }
            lunch {
              type
              semantic_time
              days
              start
              end
            }
            afternoon {
              type
              semantic_time
              days
              start
              end
            }
            evening {
              type
              semantic_time
              days
              start
              end
            }
            night {
              type
              semantic_time
              days
              start
              end
            }
          }
        }
      }
    }
  }
}
   `;

export default (appId, params) => {

    params;
    let data = {};
    data.query = query;
    data.variables = {app_id: appId}

    return JSON.stringify(data);
}


