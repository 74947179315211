/*let sampleMoment = {
    'type': 'GenericMoment',
    'start': '2018-05-23T13:30:00.000+02:00',
    'end': null,
    'analysis_type': 'preliminary',
    'moment_definition_id': 'afternoon',
    'start_ts': 1527075000000,
    'end_ts': null,
    'moment_definition': {
        'type': 'MomentDefinition',
        'id': 'afternoon',
        'display_name': 'Afternoon',
        'description': 'This moment will be active for the first half of the time between lunch and evening.',
        'category': 'semantic_time',
    },
};

let sampleEventTransport = {
    'type': 'Transport',
    'start': '2018-05-20T00:21:51+02:00',
    'end': '2018-05-20T06:07:03+02:00',
    'analysis_type': 'indepth',
    'waypoints': [
        {
            'type': 'Waypoint',
            'latitude': 53.58985,
            'longitude': 10.01939,
            'accuracy': 16,
        },
        {
            'type': 'Waypoint',
            'latitude': 53.58986,
            'longitude': 10.01939,
            'accuracy': 16,
        },
        {
            'type': 'Waypoint',
            'latitude': 53.58986,
            'longitude': 10.01941,
            'accuracy': 16,
        },
        {
            'type': 'Waypoint',
            'latitude': 53.58986,
            'longitude': 10.01941,
            'accuracy': 16,
        },
    ],
    'mode': 'idle',
    'distance': 0,
};

let sampleEventStationary = {
    'type': 'Stationary',
    'start': '2018-05-23T09:45:09.345+02:00',
    'end': null,
    'analysis_type': 'preliminary',
    'latitude': 53.54296,
    'longitude': 10.00136,
    'location': {
        'significance': 'work',
    },
};*/

import dayjs from 'dayjs';


class Entry {

    constructor() {
        this._id = null;
        this._type = null;
        this._start = null;
        this._end = null;
        this._analysis_type = null;
        this._label = null;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get start() {
        return this._start;
    }

    set start(value) {
        this._start = dayjs(value);
    }

    get end() {
        return this._end;
    }

    set end(value) {
        this._end = dayjs(value);
    }

    get analysis_type() {
        return this._analysis_type;
    }

    set analysis_type(value) {
        this._analysis_type = value;
    }

    get label() {
        return this._label;
    }

    set label(value) {
        this._label = value;
    }

}

class MomentEntry extends Entry {
    constructor() {
        super();
        this._meta = null;
    }

    static fromMoment(moment) {
        let m = new MomentEntry();
        m.type = moment.__typename;
        m.analysis_type = moment.analysis_type;
        m.start = moment.start;
        m.end = moment.end;
        m.label = moment.moment_definition.display_name;
        m.meta = {category: moment.moment_definition.category.toLowerCase()};
        return m;
    }

    get meta() {
        return this._meta;
    }

    set meta(value) {
        this._meta = value;
    }
}

class EventEntry extends Entry {

    constructor() {
        super();
        this._locations = [];
        this._meta = null;
    }

    static fromStationary(event) {
        let e = new EventEntry();
        e.type = event.type;
        e.analysis_type = event.analysis_type;
        e.start = event.start;
        e.end = event.end;
        e.label = event.location.significance;
        e.locations = [new Location(event.latitude, event.longitude, 'location', event.location)];
        e.meta = {category: event.type.toLowerCase()};
        return e;
    }

    static fromTransport(event) {
        let e = new EventEntry();
        e.type = event.type;
        e.analysis_type = event.analysis_type;
        e.start = event.start;
        e.end = event.end;
        e.label = event.mode;
        e.meta = {distance: event.distance,category: event.type.toLowerCase() };
        if(event.waypoints){
            event.waypoints.forEach((wp) => {
                e.addLocation(new Location(wp.latitude, wp.longitude, 'waypoint', {accuracy: wp.accuracy}));
            });
        }
        return e;
    }

    addLocation(location) {
        this._locations.push(location);
    }

    get meta() {
        return this._meta;
    }

    set meta(value) {
        this._meta = value;
    }

    get locations() {
        return this._locations;
    }

    set locations(value) {
        this._locations = value;
    }
}

class Location {

    constructor(lat, long, type, meta) {
        this._latitude = lat;
        this._longitude = long;
        this._type = type;
        this._meta = meta;
    }

    get meta() {
        return this._meta;
    }

    set meta(value) {
        this._meta = value;
    }

    get latitude() {
        return this._latitude;
    }

    set latitude(value) {
        this._latitude = value;
    }

    get longitude() {
        return this._longitude;
    }

    set longitude(value) {
        this._longitude = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }
}

export {
    Entry,
    MomentEntry,
    EventEntry,
    Location
}