<template>
    <div class="app-selector field is-horizontal control">
        <div class="field-label">
            <label class="label">App</label>
        </div>
        <div class="field-body">
            <div class="select">
                <select v-model="selected" title="appselect">
                    <option disabled value="">Please select one</option>
                    <option v-for="opt in options" v-bind:key="opt">{{opt}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import constants from "@/constants";
    import updateIntents from "../../api/updateIntents";

    export default {
        name: "app-selector",
        data() {
            return {
                selected: this.$store.state.selectedApp || selectedAppconstants.DEFAULT_APP,
                options: Object.keys(constants.APP_MAP)
            }
        },
        methods: {},
        watch: {
            selected() {
                console.log('selected', this.selected);
                this.$store.commit('selectedUser', []); // reset userlist
                this.$store.commit('selectedApp', this.selected);
                this.$store.dispatch('loadData', updateIntents.APP_SELECTION_CHANGED);
            }
        }
    }
</script>

<style scoped lang="scss">
    .app-selector {
        display: flex;
        align-items: center;

        .loading {
            opacity: 0.1;
        }
        
        select {
            border: none;
        }
    }

    

</style>