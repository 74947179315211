import Vue from 'vue';
import Vuex from 'vuex';
import time from './modules/time';
import constants from "../constants";


Vue.use(Vuex);

const state = {
    selectedUser: [],
    selectedApp: constants.DEFAULT_APP,
    loggedIn: false
};

const getters = {
    selectedUser(state) {
        return state.selectedUser;
    },
    selectedApp(state) {
        return state.selectedApp;
    },
    loggedIn(state) {
        return state.loggedIn;
    }
};

const mutations = {

    selectedUser(state, userList) {
        state.selectedUser = userList;
    },
    selectedApp(state, app) {
        state.selectedApp = app;
    },
    loggedIn(state, value) {
        if(!value) {
            localStorage.removeItem('login');
        }
        state.loggedIn = value;
    }
};

const actions = {
    login ({ commit}) {
        commit('loggedIn', true);
    },
    logout ({ commit}) {
        commit('loggedIn', false);
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
    modules: {
        time: time,
        //api: api
    },
    state,
    actions,
    getters,
    mutations,
});