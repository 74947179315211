<template>
    <div :class="'chart sunburst ' + id">
        <div class="info">
            <h2>{{ title }}</h2>
        </div>

        <ul class="legend">
            <li v-for="(category, index) in Object.keys(segmentDefinitions)" :key="category"
                :class="getLegendItemClass(category)"
                :data-category="category"
                v-on:click="clickHandlerCategory(category)"
                :style="{'background-color': colors[index]}">
                {{ category }}
            </li>
        </ul>
        <div>
            <svg :class="id"
                 v-on:click="clickHandlerSubCategory"
            ></svg>
        </div>
    </div>
</template>

<script>
    import Chart from './charts/sunburst';

    const CLASS_INACTIVE = "inactive";

    export default {
        name: "SunburstChart",
        props: {
            id: String,
            title: String,
            userGroup: Array,
            segmentDefinitions: Object,
            filterCategory: {default: ""},
            filterSubCategory: {default: ""},
            usersKey: {default: "active_users"},
            dataAxisKey: {default: "display_name"},
            includeEmpty: {default: false},
            colors: {default: () => ["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"]},
            colorFaded: {default: "rgba(0,0,0,0.25)"},
            valueLabelColor: {default: "#FFF"}
        },
        data() {
            return {
                selectedCategory: this.filterCategory,
                selectedSubCategory: this.filterSubCategory,
                selectedSubCategoryCategory: "",
                resetSubCategorySelection: false,
                dataTotal: 0,
                labelSelectionBottom: "Users",
                elements: {} // fill with unwatched elements of interest for UI usage
            }
        },
        mounted() {
            this.renderChart();

            this.elements.legendItems = this.$el.querySelectorAll(".legend li");
        },
        methods: {
            renderChart() {
                //console.log("RENDER - " + this.title + " |", this.selectedCategory, "|", this.selectedSubCategory);

                let baseValuesObj = this.getChartBaseValuesObject(this.segmentDefinitions); // use to include empty subcategories (unused definitions)
                let userGroup = this.applyCategoryFilter(this.userGroup);
                userGroup = this.applySubCategoryFilter(userGroup);
                let values = this.getChartValues(userGroup, baseValuesObj);
                let data = this.formatData(values);


                Chart(this.id, data, {
                    fontSize: 11,
                    colors: this.colors,
                    colorFaded: this.colorFaded,
                    valueLabelColor: this.valueLabelColor,
                    equalWidth: this.includeEmpty,
                    labelTopRenderedCount: this.selectedSubCategory || "Total",
                    labelBottomRenderedCount: this.labelSelectionBottom,
                    renderedCount: userGroup.length
                });

                this.dataTotal = this.userGroup.length;
            },
            formatData(values) {
                // the structure that the d3 chart wants
                let data = [];
                for (let key in values) {
                    data.push({
                        axis: key,
                        value: values[key].value,
                        context: values[key].category,
                        index: Object.keys(this.segmentDefinitions).indexOf(values[key].category)
                    })
                }
                return data;
            },
            applyCategoryFilter(userGroup) {
                // filters to only include SEGMENTS IN USERS where category === selection
                if (this.selectedCategory) {
                    let filtered = [];
                    for (let user of userGroup) {
                        let segments = [];
                        for (let segment of user.segments) {
                            let s = segment.segment_definition;
                            if (this.selectedCategory === s.category) {
                                segments.push(segment);
                            }
                        }
                        let userCopy = {...user};
                        userCopy.segments = segments;
                        filtered.push(userCopy);
                    }
                    return filtered
                } else {
                    return userGroup; // skip filtering
                }
            },
            applySubCategoryFilter(userGroup) {
                // filters to only include USERS that have at least one where segment type === selection
                if (this.selectedSubCategory) {
                    let filtered = [];
                    for (let user of userGroup) {
                        for (let segment of user.segments) {
                            let s = segment.segment_definition;
                            if (this.selectedSubCategory === s[this.dataAxisKey]) {
                                filtered.push(user);
                                break;
                            }
                        }
                    }
                    return filtered;
                } else {
                    return userGroup // skip filtering
                }
            },
            getChartValues(userGroup, valuesObj = {}) {
                // reduce user segments to a set of value-objects, one for each subcategory (segment id)
                for (let user of userGroup) {
                    for (let segment of user.segments) {
                        let s = segment.segment_definition;
                        if (!valuesObj.hasOwnProperty(s[this.dataAxisKey])) { // needed for dynamic categories even if we pre-populate baseValuesObject
                            valuesObj[s[this.dataAxisKey]] = {
                                value: 0,
                                category: s.category
                            }
                        }
                        valuesObj[s[this.dataAxisKey]].value++;
                    }
                }
                return this.normalizeGroupValues(valuesObj, userGroup.length);
            },
            normalizeGroupValues(groupValues, maxValue) {
                // turn absolute values into % of total
                for (let key in groupValues) {
                    groupValues[key].value = parseFloat((groupValues[key].value / (maxValue === 0 ? 1 : maxValue)).toFixed(3));
                }
                return groupValues
            },
            getChartBaseValuesObject(segmentDefinitions) {
                // pre-fill with unused segments that may not occur in data
                let definitions = [];
                for (let key in segmentDefinitions) {
                    definitions = definitions.concat(segmentDefinitions[key])
                }

                let valuesObj = {};
                for (let segmentDefinition of definitions) {
                    valuesObj[segmentDefinition[this.dataAxisKey]] = {
                        value: 0,
                        category: segmentDefinition.category
                    };
                }
                return valuesObj;
            },
            clickHandlerCategory(category) {
                if (this.selectedSubCategoryCategory !== category) this.selectedSubCategory = "";
                this.selectedCategory = this.selectedCategory === category ? "" : category;
            },
            clickHandlerSubCategory(event) {
                let subCategory = event.target.parentElement.dataset.target;
                let category = event.target.parentElement.dataset.context;
                
                if (subCategory && category && this.selectedSubCategory !== subCategory) {
                    this.selectedSubCategory = subCategory;
                    this.selectedSubCategoryCategory = category;
                } else {
                    this.selectedSubCategory = ""; // deselect on any invalid element
                    this.selectedSubCategoryCategory = "";
                }
            },
            getLegendItemClass(category) {
                if (this.selectedCategory) {
                    return this.selectedCategory === category ? "" : CLASS_INACTIVE // fade others
                } else {
                    return "" // all normal
                }

            }
        },
        watch: {
            includeEmpty() {
                this.renderChart()
            },
            userGroup() {
                this.renderChart()
            },
            dataAxisKey() {
                this.renderChart()
            },
            segmentDefinitions() {
                this.renderChart()
            },
            selectedCategory() {
                this.renderChart()
            },
            selectedSubCategory() {
                this.renderChart()  
            },
        },
        updated() {
            this.renderChart();
        }
    };
</script>

<style lang="scss">
    .chart.sunburst {
        position: relative;
        
        max-width: 1200px;
        margin: 0 auto;

        .info {
            display: flex;
            justify-content: center;
            align-items: center;

            > {
                //flex: ;
            }
        }

        .legend {
            position: absolute;
            top: 5%;
            left: 2%;

            color: white;
            //font-weight: lighter;

            li {
                padding: 2px 10px;

                &.inactive {
                    opacity: 0.3;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        svg {
            //font-size: 11px;
            //font-weight: lighter;

            > g {
                g > * {
                }

                .arc-data {

                }

                .arc-labels-values {
                    text {
                    }
                }

                .arc-labels-categories {
                    text {
                        fill: black
                    }
                }
            }
        }
    }
</style>