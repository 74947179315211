<template>
    <div class="field">
        <button class="button is-primary is-inverted" v-on:click="reload">Reload</button>
    </div>
</template>

<script>
    import updateIntents from "../../api/updateIntents";

    export default {
        name: "load-button",
        methods: {
            reload: function() {
                this.$store.dispatch('loadData', updateIntents.RELOAD_BUTTON);
            }
        }
    }
</script>

<style scoped>

</style>