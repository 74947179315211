import MapHelper from "./helper/mapHelper";

export default class GoogleMapController {

    constructor(element) {

        const options = {
            zoom: 9,
            center: {lat: 53.63021, lng: 10},
            zoomControl: true,
            scaleControl: true,
            streetViewControl: false,
            mapTypeControl: false
        }
        this.markers = [];
        this.paths = [];
        this.groups = {};
        this.mapInstance = new google.maps.Map(element, options);

        this.infowindow = new google.maps.InfoWindow();
        this.heatmapLayers = [];
    }

    centerMap() {
        this.mapInstance.setZoom(9);
        this.mapInstance.setCenter({lat: 53.63021, lng: 10});
    }

    reset() {
        // refresh the map

        console.log("RESET THE MAP INSTANCE", this.mapInstance);


        this.centerMap();

        this.groups = {};

        while (this.markers.length > 0) {
            let marker = this.markers.pop();
            marker.setMap(null);
            marker = null;
        }
        this.markers = [];

        while (this.paths.length > 0) {
            let path = this.paths.pop();
            path.setMap(null);
            path = null;
        }
        this.paths = [];
        this.resetHeatmap();
    }

    addPaths(pathList, groupName, color = '#ff0000') {

        if (!this.groups[groupName]) {
            this.groups[groupName] = {locations: [], paths: []};
        }

        for (let path of pathList) {

            let lineOptions = {
                path: path.coordinates,
                color: color,
                data: path,
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 1,
                editable: false
            };


            let line = new google.maps.Polyline(lineOptions);
            line.setMap(this.mapInstance);
            this.paths.push(line);
            line.addListener('click', (event) => {

                this.openPath(event, line);
            });

            line.addListener('mouseover', () => {
                line.setOptions({strokeColor: '#ff0000'});
            });

            line.addListener('mouseout', () => {
                line.setOptions({strokeColor: line.color});
            });

            this.groups[groupName].paths.push(line);
        }


    }

    addLocations(locationList, groupName, pinColor = 'ff0000') {

        if (!this.groups[groupName]) {
            this.groups[groupName] = {locations: [], paths: []};
        }

        let locationData = null;
        for (let location of locationList) {

            try {
                locationData = location.event.location;
            }
            catch (e) {
                locationData = null;
            }


            let options = Object.assign(location, {icon: MapHelper.getPinTypeByLocation(locationData, pinColor)});
            let marker = new google.maps.Marker(options);
            marker.id = location.id + Math.random();
            marker.group = groupName;
            marker.visible = true;
            marker.addListener('click', () => {
                this.openMarker(marker);
            });

            marker.setMap(this.mapInstance);
            //  this.oms.addMarker(marker);
            this.markers.push(marker);

            this.groups[groupName].locations.push(marker);
        }

    }

    resetHeatmap() {
        console.log('reseting heatmap');
        while (this.heatmapLayers.length > 0) {
            let layer = this.heatmapLayers.pop();
            layer.setMap(null);
        }
    }

    addHeatmapLayer(locationlist) {
        let heatmapData = [];
        locationlist.forEach(location => {
            heatmapData.push({
                location: new google.maps.LatLng(location['lat'], location['lon']),
                weight: location['weight'] || 2
            });
        });

        this.heatmapLayers.push(new google.maps.visualization.HeatmapLayer({
                data: heatmapData,
                map: this.mapInstance
            })
        );
    }

    openMarker(markerOrId) {
        let marker = null;
        if (typeof markerOrId == 'string') {
            marker = this.markers.find((item) => {
                return item.id == markerOrId
            });
        }
        else {
            marker = markerOrId;
        }


        if (marker) {
            let content = '' +
                '<h3>' + marker.title + '</h3>' +
                '<p>Start:' + marker.start + '</p>' +
                '<p>Group:' + marker.group + '</p>' +
                '<p>Duration:' + marker.duration + '</p>' +
                '<p>' + marker.raw + '</p>';

            this.infowindow.setContent(content);
            this.infowindow.open(this.mapInstance, marker);
        }
    }

    openPath(event, path) {
        if (path) {

            let content = '' +
                '<h3>' + path.data.mode + '</h3>' +
                '<p>Start:' + path.data.start + '</p>' +
                '<p>Duration:' + path.data.duration + '</p>' +
                '<p>Distance:' + path.data.distance + '</p>';

            this.infowindow.setContent(content);
            this.infowindow.setPosition(event.latLng);
            this.infowindow.open(this.mapInstance, path);
        }
    }

    showAllGroups() {
        console.log("show all");
        for (let marker of this.markers) {
            marker.setVisible(true);
        }
        for (let line of this.paths) {
            line.setVisible(true);
        }
    }

    hideAllGroups() {
        for (let marker of this.markers) {
            marker.setVisible(false);
        }
        for (let line of this.paths) {
            line.setVisible(false);
        }

    }

    showGroup(groupName) {

        if (this.groups[groupName]) {
            for (let marker of this.groups[groupName].locations) {
                marker.setVisible(true);
            }
            for (let line of this.groups[groupName].paths) {
                line.setVisible(true);
            }
        }
    }

    hideGroup(groupName) {
        for (let marker of this.groups[groupName].locations) {
            marker.setVisible(false);
        }
        for (let line of this.groups[groupName].paths) {
            line.setVisible(false);
        }
    }


}