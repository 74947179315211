<template>
    <svg :class="semanticTimeLabel"></svg>
</template>

<script>
    import * as d3 from 'd3';

    export default {
        name: "DayChart",
        props: ['userData', 'semanticTimeLabel', 'width'],
        data() {
            return {
                height: 400,
                margin: 25
            }
        },
        watch: {
            userData: function () {
                this.render()
            }
        },
        computed: {
            userCount() {
                return this.userData.length
            },
            xAxisLength() {
                return this.width - 2 * this.margin;
            },
            yAxisLength() {
                return this.height - 2 * this.margin;
            },

            svgContainer() {
                return d3.select("svg." + this.semanticTimeLabel)
            },

            data() {
                let data = [];
                let half_hour = Date.parse('01 Jan 1970 00:30:00 GMT');
                for (let i = 0; i < 48; i++) {
                    let timestamp = i * half_hour;
                    let tsData = {};
                    let date = new Date(timestamp);
                    let minutes = ('' + date.getUTCMinutes()).padStart(2, '00');
                    tsData['time'] = date.getUTCHours() + ':' + minutes;

                    this.userData.forEach((user, userIndex) => {
                        tsData[userIndex] = this.isInRange(userIndex, this.semanticTimeLabel, timestamp)
                    });
                    data.push(tsData)
                }
                return data
            },

            series() {
                let data = this.data;
                let keys = this.keys;

                let stack = d3.stack()
                    .keys(keys);
                return stack(data);
            },

            keys() {
                let keys = [];
                this.userData.forEach((user, userIndex) => {
                    keys.push(userIndex)
                });
                return keys
            },

        },
        methods: {
            dayTimeToDate(startTime, endTime) {
                let startStamp = Date.parse('01 Jan 1970 ' + startTime + ' GMT');
                let endStamp = Date.parse('01 Jan 1970 ' + endTime + ' GMT');
                return [startStamp, endStamp]
            },
            /**
             * userIndex: index in userData
             * label: afternoon, evening, lunch, morning, night
             * time: timestamp to check if its in
             * */
            isInRange(userIndex, label, time) {
                //console.log('reading data for user', userIndex, ': ', this.userData[userIndex])
                if (!this.userData[userIndex]['semantic_time']) {
                    return 0
                }
                let userTimes = this.userData[userIndex]['semantic_time']['all_days'][label];
                let [start, end] = this.dayTimeToDate(userTimes['start'], userTimes['end']);

                if (end > start) {
                    if (time >= start && time < end) {
                        return 1
                    } else {
                        return 0
                    }
                }

                else {
                    if (time >= start || time < end) {
                        return 1
                    } else {
                        return 0
                    }
                }
            },
            extractValues(series) {
                let values = [];
                series.forEach(function (value) {
                    value.forEach(function (item) {
                        values = values.concat(item);
                    });
                });
                return values;
            },

            stackMin() {
                let values = this.extractValues(this.series);
                return d3.min(values);
            },

            stackMax() {
                let values = this.extractValues(this.series);
                return d3.max(values);
            },

            renderXAxis() {
                let margin = this.margin;
                let height = this.height;
                let xAxisLength = this.xAxisLength;

                let data = this.data;
                let xScale = d3.scaleBand()
                    .domain(data.map((d) => {
                        return d.time;
                    })).range([0, xAxisLength]);


                let xAxis = d3.axisBottom(xScale)
                    .tickValues(xScale.domain().filter(function (d, i) {
                        // display only every 2nd element in legend
                        return !(i % 2)
                    }));

                this.svgContainer.append("g")
                    .classed("x-axis", true)
                    .attr("transform", () => {
                        return "translate(" + margin + "," + (height - margin) + ")";
                    })
                    .call(xAxis);
                return xScale
            },

            renderYAxis() {
                let margin = this.margin;
                let yAxisLength = this.yAxisLength;

                let stackMax = this.stackMax();
                let stackMin = this.stackMin();

                let yScale = d3.scaleLinear()
                    .domain([stackMax, stackMin])
                    .range([0, yAxisLength]).clamp(true);

                let yAxis = d3.axisLeft(yScale);

                this.svgContainer.append("g")
                    .classed("y-axis", true)
                    .attr("transform", function () {
                        return "translate(" + margin + "," + margin + ")";
                    })
                    .call(yAxis);

                this.svgContainer.selectAll("g.y-axis g.tick")
                    .append("line")
                    .classed("grid-line", true)
                    .attr("x1", 0)
                    .attr("y1", 0)
                    .attr("x2", yAxisLength)
                    .attr("y2", 0);
            },
            render() {
                console.log('rendering...')
                let svg = this.svgContainer;
                svg.selectAll("*").remove();

                let margin = this.margin;
                let width = this.width;
                let height = this.height;

                svg.attr("width", width)
                    .attr("height", height)
                    .style("border", "1px solid");

                let xScale = this.renderXAxis();
                this.renderYAxis();

                let yAxisLength = this.yAxisLength;
                let series = this.series;

                let stackMax = this.stackMax();
                let stackMin = this.stackMin();


                let rectWidth = xScale.bandwidth();

                let yScale = d3.scaleLinear()
                    .domain([stackMax, stackMin])
                    .range([0, yAxisLength])

                let colorScale = () => {return '#aa9999'};

                svg.append("g")
                    .classed("stack-bar", true)
                    .attr("transform", "translate(" + (margin + width / 100) + "," + margin + ")")
                    .selectAll("g")
                    .data(series)
                    .enter()
                    .append("g")

                    .attr("fill", function (d) {
                        return colorScale(d.key)
                    })
                    .selectAll("rect")
                    .data(function (d) {
                        return d;
                    })
                    .enter()
                    .append("rect")
                    .attr("x", function (d, i) {
                        return (rectWidth * i);
                    })
                    .attr("y", function (d) {
                        return yScale(d["1"])
                    })
                    .attr("width", rectWidth)
                    .attr("height", function (d) {
                        return yAxisLength - (yScale(d["1"] - d["0"]))
                    })
            },
        },
        mounted() {
            this.render()
        }
    }
</script>

