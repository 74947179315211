import * as d3 from 'd3';


export default function sunburst(id, data, options) {

    let opts = Object.assign({
            width: 1000, // matters in relation to font-size (higher = smaller font when scaled down)
            height: 1000,
            padding: 30,
            fontSize: 11,
            colors: [],
            colorFaded: "",
            valueLabelColor: "",
            equalWidth: false,
            labelTopRenderedCount: "",
            labelBottomRenderedCount: "",
            renderedCount: 0, // display what values shown are based on, which is not known by the dataset used here (ex: data is based on amount of users)
            valueLabelOffsetDataThreshold: 0.17
        
        }, options);
    
    let svg = d3.select("svg." + id),
        radius = (Math.min(opts.width, opts.height) / 2) - opts.padding,
        pi = 3.1415926536;

    //Clear previous svg content
    svg.select("svg > g").remove();
    
    svg.attr("viewBox", `${opts.width / -2} ${opts.height / -2} ${opts.width} ${opts.height}`); // 0, 0 = center

    let g = svg.append("g");
    
    let pie = d3.pie()
        .sort(function (a, b) {
            return a.index - b.index
        })
        .value(function (d) {
            return opts.equalWidth ? 1 : d.value;
        });

    let _data = pie(data);
    

    let path = d3.arc()
        //.outerRadius(radius * 0.4) // overwritten with values for pie-burst effect
        .innerRadius(radius * 0.5);


    let arcData = g.selectAll(".nonsense1")
        .data(_data)
        .enter().append("g")
        .attr("class", "arc-data")
        .attr("data-target", function(d) {return d.data.axis})
        .attr("data-context", function(d) {return d.data.context});

    let arcValueLabels = g.selectAll(".nonsense2")
        .data(_data)
        .enter().append("g")
        .attr("class", "arc-labels-values")
        .attr("data-target", function(d) {return d.data.axis})
        .attr("data-context", function(d) {return d.data.context});

    let arcSubCategoryLabels = g.selectAll(".nonsense3")
        .data(_data)
        .enter().append("g")
        .attr("class", "arc-labels-subcategories")
        .attr("data-target", function(d) {return d.data.axis})
        .attr("data-context", function(d) {return d.data.context});
    
    let datumCountLabel = g.append("g")
        .attr("class", "arc-meta-counter");
        
    
    // pie-slices
    arcData.append("path")
        .attr("d", path.outerRadius(function (d) {
            return radius * 0.5 + (d.data.value * (radius * 0.5));
        }))
        .attr("stroke", function(d) {return opts.colors[d.data.index]})
        .attr("stroke-width", function(d) {
            if (d.data.value > 0) {
                return "1px"
            } else {
                return 0
            }
        })
        .attr("fill", function (d) {
            return opts.colors[d.data.index];
        });

    // displayed value for each pie-slice
    arcValueLabels.append("text")
        .attr("font-size", opts.fontSize)
        .attr("fill", function(d) {return d.data.value < opts.valueLabelOffsetDataThreshold ? opts.colors[d.data.index] : opts.valueLabelColor})
        .attr("transform", function(d) {
                let rad = (d.endAngle - d.startAngle) / 2;
                let leftRight = pi > d.startAngle + rad;
                let degOffset = pi/2 * (leftRight ? -1 : 1) ;

                return "translate(" +
                    path.centroid(d) +
                    ") rotate(" +
                    (d.startAngle + rad + degOffset) * 180 / pi +
                    ")";
            })
        .attr("dy", "0.4em")
        .attr("dx", function(d) {
            let rad = (d.endAngle - d.startAngle) / 2;
            let leftRight = pi > d.startAngle + rad;
            let offset = leftRight ? ((d.data.value * 11) + 0.4) : -((d.data.value * 13) + 3);
            
            return d.data.value < opts.valueLabelOffsetDataThreshold ? `${offset}em` : "-1.2em"
        })
        .text(function (d) {
            if (d.data.value > 0) {
                return Math.round(d.data.value * 1000) / 10 + "%";
            } else {
                return ""
            }
        });

    // text labels next to pie-slices
    arcSubCategoryLabels.append("text")
        .attr("font-size", opts.fontSize)
        .attr("text-anchor", function(d) {return pi > d.startAngle + ((d.endAngle - d.startAngle) / 2) ? "end" :  "start"})
        .attr("fill", function(d) {
            if (d.data.value > 0) {
                return opts.colors[d.data.index]
            } else if (opts.equalWidth && d.data.value === 0) {
                return opts.colorFaded;
            } else {
                return ""
            }
        })
        .attr("transform", function (d) {
            let rad = (d.endAngle - d.startAngle) / 2;
            let leftRight = pi > d.startAngle + rad;
            let posOffset = leftRight ? 0.51 : 0.462; // align text against path.innerRadius TODO adjusted for 11px font-size, and wont scale currently
            let degOffset = pi/2 * (leftRight ? -1 : 1) ; // rotate text so that none are "upside down"

            return "translate(" +
                (radius * posOffset) * Math.sin(rad + d.startAngle) +
                ", " +
                -1 * (radius * posOffset) * Math.cos(rad + d.startAngle) +
                ") rotate(" +
                (d.startAngle + rad + degOffset) * 180 / pi +
                ")";
        })
        .attr("dy", "0.4em")
        .attr("dx", "-1em")
        .text(function (d) {
            if (d.data.value > 0 || opts.equalWidth) {
                return d.data.axis;
            } else {
                return ""
            }
        });


    // circle-center counter
    datumCountLabel.append("text")
        .attr("class", "top-label")
        .attr("text-anchor", "middle")
        .attr("font-size", opts.fontSize * 2)
        .attr("font-weight", "lighter")
        .attr("stroke", "black")
        .attr("y", -Math.round(opts.fontSize * 3.5) / 2)
        .text(() => opts.labelTopRenderedCount);
    
    datumCountLabel.append("text")
        .attr("class", "bottom-label")
        .attr("text-anchor", "middle")
        .attr("font-size", opts.fontSize * 2)
        .attr("font-weight", "lighter")
        .attr("stroke", "black")
        .attr("y", Math.round(opts.fontSize * 4.2)) 
        .text(() => opts.labelBottomRenderedCount);
    
    datumCountLabel.append("text")
        .attr("class", "value")
        .attr("text-anchor", "middle")
        .attr("font-size", opts.fontSize * 4)
        .attr("stroke", "black")
        .attr("y", Math.round(opts.fontSize * 4) / 2)
        .text(() => opts.renderedCount);

}



