<template>
    <div v-if="entries && entries.length > 0" class="entry-timeline">
        <div class="timeline-label" v-if="label">{{ label }} <span>({{entries.length}})</span></div>
        <EntryLane class="entry-lane" v-for="lane in lanes"
                   v-bind:key="lane.id"
                   v-bind:entries="lane.entries"
                   v-bind:label="lane.label"
                   v-bind:startdate="startdate"
                   v-bind:scale="scale"></EntryLane>
    </div>
</template>

<script>
    import EntryLane from './EntryLane';


    export default {
        name: 'EntryTimeline',
        components: {
            EntryLane,
        },
        props: {
            entries: Array,
            label: String,
            scale: Number,
            startdate: String,
        },
        computed: {
            lanes: function() {
                let slots = {};
                this.entries.forEach((e) => {
                    if (!e.meta) {
                        console.warn(e);
                    }
                    if (e.meta && e.label && !slots[e.label]) {
                        slots[e.label] = [];
                    }
                    slots[e.label].push(e);
                });

                let lanes = [];
                for (let s in slots) {
                    if (slots.hasOwnProperty(s)) {
                        let l = {
                            entries: slots[s],
                            id: encodeURI(s),
                            label: s,

                        };
                        lanes.push(l);
                    }

                }
                return lanes;
            },
        },
    };
</script>

<style scoped>
    .entry-timeline {
        margin: 10px 0 30px;
        position: relative;
    }

    .timeline-label {
        font-size: 12px;
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
        background-color: rgba(1, 1, 1, 0.6);
        color: white;
        padding: 3px 0 3px 3px;
        text-align: left;
    }
</style>