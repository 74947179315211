// root state object.
// each Vuex instance is just a single state tree.
//import MUTATIONS from "../mutation-types";
import Api from '../../api/api';
import constants from "../../constants";
import PostProcess from "./helper/postProcess";
import updateIntents from "../../api/updateIntents";

/**
 * Custom store for segment charts view that need data from multiple apps.
 * Temp solution as the base store.module.api did not support this at this time.
 **/
export default {

    state() {
        return {
            segmentData: [],
            appsData: [],
            segmentsQuery: null,
            appLoadSettings: null,
            isLoading: false,
            load_settings: {}
        }
    },

    namespaced: true,

    getters: {
        segmentData(state) {
            return state.segmentData;
        },
        segmentsQuery(state) {
            return state.segmentsQuery;
        },
        appsData(state) {
            return state.appsData;
        },
        appLoadSettings(state) {
            return state.appLoadSettings;
        },
        isLoading(state) {
            return state.isLoading;
        },
        load_settings(state) {
            return state.load_settings;
        }
    },

    mutations: {
        setSegmentData(state, data) {
            state.segmentData = data;
        },
        setAppsData(state, data) {
            state.appsData = data;
        },
        segmentsQuery(state, query) {
            state.segmentsQuery = query;
        },
        appLoadSettings(state, query) {
            state.appLoadSettings = query;
        },
        isLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        load_settings(state, load_settings) {
            state.load_settings = load_settings;
        }
    },

    actions: {
        'loadData': {
            root: true,
            handler(namespacedContext, loadIntent) {

                namespacedContext.commit('isLoading', true);


                // check if this store wants to update
                let settings = namespacedContext.state.load_settings;
                let matchTheLoadIntent = ( (loadIntent && loadIntent===updateIntents.INIT) || settings.updateOn.indexOf(loadIntent) > -1);
                console.log("X-QUERY match loadingitent", matchTheLoadIntent, loadIntent,settings.updateOn);
                if( !matchTheLoadIntent) {
                    console.log("X-QUERY, STORE not interested in this loadIntent", settings.name, loadIntent, settings.updateOn);
                    return;
                }
                Api.getDataByCallParams(settings).then((segmentDefinitions) => {
                    let processed = PostProcess.segmentDefinitions(segmentDefinitions.data.segment_definitions);
                        namespacedContext.commit('setSegmentData', processed);

                    }).catch((error) => {

                    console.log("load error", error)
                });

                // proceed to load users for all apps
                namespacedContext.dispatch('loadAllApps')
            }

        },
        loadAllApps({state, commit, rootState}) {
            rootState;
            let appUsers = [];
            let promises = [];
            for (let app of Object.keys(constants.APP_MAP)) {
                let settings = state.appLoadSettings;
                settings.app=app;
                promises.push(Api.getDataByCallParams(state.appLoadSettings).then((users) => {

                    appUsers.push(users.data.app); // append users from each app
                }).catch((error) => {
                    console.log("load error", error)
                }))



            }

            Promise.all(promises)
                .then(() => {
                    commit('setAppsData', appUsers);

                    // all have finished
                    commit('isLoading', false);
                })

        }
    }
}

