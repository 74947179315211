import LogUtil from '../core/utils/logUtil';
import constants from '../constants';


const byString = function(o, s) {
    if (s === '' || s === null || s === undefined) {
        return o;
    }
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

const getAppIdForApp = (appName) => {

    return constants.APP_MAP[appName] || constants.APP_MAP[constants.DEFAULT_APP];
};

export default class Api {

    static getDataByCallParams(params) {

        let appID = getAppIdForApp(params.app);
        let loggedIn = localStorage.getItem('login') && localStorage.getItem('login') !== null && localStorage.getItem('login') != 'null';

        if(!loggedIn) {
            return Promise.reject("not logged in");
        }

        // create the query
        let queryInstance = params.query(appID, params);
        LogUtil.log("X-QUERY '"+params.name+"' START");

        if(queryInstance) {

            let api_base_url = constants.API_URL+"/"+appID;
            return Api.performApiCall(api_base_url, 'POST', queryInstance).then( (data) => {

                LogUtil.log("X-QUERY '"+params.name+"' RESULT:", data);
                return data;

            }).catch((error)=> {
                LogUtil.log("X-QUERY '"+params.name+"' ERROR", error);
            })
        }
        else {
            return Promise.reject('X-QUERY NO QUERY');
        }
    }


    static getData(query, params) {
        let {app, node_path} = params;

        let appID = getAppIdForApp(app);

        let loggedIn = localStorage.getItem('login') && localStorage.getItem('login') !== null && localStorage.getItem('login') != 'null';

        if(!loggedIn) {
            return Promise.reject("not logged in");
        }

        // create the query
        let queryInstance = query(appID, params);

        console.log("QUERY NAME", params.name, app, appID)
        console.log(params);
        // try to get the object path

        node_path = (params.node_path === undefined) ?  'data.app.active_users.slice': node_path;

        if(queryInstance) {
            let api_base_url = constants.API_URL+"/"+appID;

            return Api.performApiCall(api_base_url, 'POST', queryInstance).then( (data) => {

                let result = byString(data, node_path);


                console.log("QUERY RESULT FOR NAME", params.name);

                if(Array.isArray(result)) {
                    return result;
                }
                else {
                    return [result];
                }

            }).catch((error)=> {
                LogUtil.log("LOAD ERROR", error);
            })

        }
        else {
            return Promise.reject('query missing');
        }

    }

    static performApiCall(url, method = 'GET', body = null) {

        return fetch(url, {
            method: method,
            body: body,
            headers: Api.getHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    LogUtil.log('RESPONSE ERROR resp', response);
                    return response.json()
                                   .then((json) => {
                                       if (response.status == 401) {
                                           return Promise.reject(new Error(JSON.stringify(json)));
                                       }
                                       else {
                                           LogUtil.log('RESP ERROR', json);
                                           return Promise.reject(new Error(JSON.stringify(json)));
                                       }
                                   });
                }
            })
            .catch((error) => {

                LogUtil.log('RESPONSE ERROR', error);
                return Promise.reject(error);
            });

    }

    static getHeaders() {
        let headersObject = {'Content-Type': 'application/json'};
        let apiKey = localStorage.getItem('login');
        headersObject['Authorization'] = 'Basic ' + btoa(apiKey);
        return new Headers(headersObject);
    }

}
