export default class PostProcess {
    static segmentDefinitions(segmentDefinitions) {

        if(!segmentDefinitions) {
            return {};
        }
        // sort into separate collections under category key, instead of all mixed together
        let byCategory = {};
        for (let segment of segmentDefinitions) {
            if (!byCategory[segment.category]) {
                byCategory[segment.category] = [];
            }
            byCategory[segment.category].push(segment)
        }
        return byCategory;
    }
}