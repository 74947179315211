
const query =`
    query ($app_id: String!){
    app(id: $app_id) {
    id
    active_users {
    slice {
    id
  
    
  }
  }
  }
  }`;

export default (appId, params) => {

  params;
  let data = {};
  data.query = query;
  data.variables = {app_id:appId}

  return JSON.stringify(data);
}


