<template>
    <div class="map-component" v-bind:class="{ loading: isLoading }">
        <GoogleMap name="google_map_example" ref="googlemap"/>
    </div>

</template>


<script>
    import GoogleMap from '../components/map/GoogleMap';
    import dayjs from 'dayjs';
    import {mapState, mapGetters} from 'vuex'
    import getUserMetaById from '../utils/userUtil'

    import store from '../store/index';
    import api from '../store/modules/api';
    import default_query from '../api/queries/default_query';
    import ApiCallParamsVO from "../vo/apiCallParamsVO";
    import updateIntents from "../api/updateIntents";


    function getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function parseWaypointsToPath(waypoints) {
        let coords = [];
        if (waypoints) {
            for (let point of waypoints) {

                coords.push({lat: point.latitude, lng: point.longitude});
            }
        }
        return coords;
    }

    function parseTitle(location) {

        let str = '';
        if (location) {
            str += location.significance;

            if (location.place) {
                if (location.place.name) {
                    str += ' | ' + location.place.name;
                }

                if (location.place.category_hierarchy) {
                    str += ' [';
                    for (let cat of location.place.category_hierarchy) {
                        str += cat + ',';
                    }
                    str += ']';
                }
            }
        }
        return str;
    }

    function getDataPerUser(user) {
        let events = user.event_history;
        let locations = [];
        let transports = [];

        for (let event of events) {
            if (event.type === 'Stationary') {
                let start = dayjs(event.start);
                let end = dayjs(event.end);
                let loc = {
                    position: {
                        lat: event.latitude,
                        lng: event.longitude,
                    },
                    start: start.format('DD.MM.YYYY HH:mm'),
                    duration: end.diff(start, 'minutes') + ' min',
                    visible: true,
                    event: event,
                    raw: JSON.stringify(event, null, 4),
                    title: parseTitle(event.location),
                };

                locations.push(loc);
            }
            else if (event.type === 'Transport') {

                let start = dayjs(event.start);
                let end = dayjs(event.end);

                let transport = {
                    coordinates: parseWaypointsToPath(event.waypoints),
                    start: start.format('DD.MM.YYYY HH:mm'),
                    duration: end.diff(start, 'minutes') + ' min',
                    distance: event.distance,
                    mode: event.mode,
                };

                transports.push(transport);
            }
        }

        return {locations: locations, transports: transports};

    }

    function loadComplete(scope) {
        let userGroups = {};

        scope.$refs.googlemap.reset();
        for (let user of scope.data) {

            userGroups[user.id] = getDataPerUser(user);

            let userMeta = getUserMetaById(user.id);
            let userColor = userMeta.color || getRandomColor();

            scope.$refs.googlemap.addPaths(userGroups[user.id].transports, userMeta.id, userColor);
            scope.$refs.googlemap.addLocations(userGroups[user.id].locations, userMeta.id, userColor);

        }

        showSelectedUser(scope);
    }


    let apiCallParams = new ApiCallParamsVO();
    apiCallParams.query = default_query;
    apiCallParams.name="map-query";
    apiCallParams.object_path="data.app.active_users.slice";
    apiCallParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.TIME_RANGE_CHANGED, updateIntents.RELOAD_BUTTON];

    store.registerModule('map-store', api);
    store.commit('map-store/load_settings', apiCallParams);


    function showSelectedUser(scope) {
        let selectedUser = scope.$store.state.selectedUser;
        console.log("on User change", selectedUser);
        if(!selectedUser || selectedUser.length ==0 ) {
            scope.$refs.googlemap.showAllGroups();
        }
        else {
            scope.$refs.googlemap.hideAllGroups();
            for(let item of selectedUser) {
                scope.$refs.googlemap.showGroup(item.id);
            }
        }
    }

    export default {
        name: 'map-view',
        components: {
            GoogleMap,
        },
        computed: {

            ...mapState({
                //data: state => state['map-store'].data,
                selectedUser: state => state.selectedUser
            }
            ),
            ...mapGetters({
                data: 'map-store/userData'

            }),
                isLoading() { return this.$store.state['map-store'].isLoading;},


        },
        watch: {
            // whenever question changes, this function will run
            data: function(items) {
                console.log('got the data', items);
                loadComplete(this);
            },
            selectedUser: function() {
                showSelectedUser(this);
            }
        },
        mounted() {
            console.log("MOUNTED");
            if(this.data && this.data.length > 0) {
                loadComplete(this)
            }
        }
    };
</script>

<style lang="scss">
    .loading {
        opacity: 0.1;
    }
</style>