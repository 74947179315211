const query = `
   query {
        segment_definitions {
            type
            id
            version
            category
            subcategory
            display_name
            description
            deprecated
            deprecated_at
        }
   }
`;

export default (appId, params) => {

    let data = {};
    data.query = query;
    data.variables = {};
    data.params = params;

    return JSON.stringify(data);
}


