const query = `
   query ($app_id: String!) {
  app(id: $app_id) {
    id
    type
    name
    description
    active_users {
      slice {
        id
        type
        created_at
        car_behavior {
          type
          scores {
            type
            l7d {
              type
              all {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
            }
            past {
              type
              all {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
              total {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
              motorway {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
              city {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
              non_city {
                type
                overall
                smooth
                legal
                anticipative
                focus
                mounted
              }
            }
          }
          features {
            type
            l7d {
              type
              phone_handling
              distance
            }
            past {
              type
              phone_handling
              distance
            }
          }
        }
        metadata
        segments {
          type
          segment_definition_id
          segment_definition {
            type
            id
            version
            category
            subcategory
            display_name
            description
            deprecated
            deprecated_at
          }
        }
        
        attributes {
          type
        }
        
       
        transport_heatmaps {
          type
          l30d {
            type
            car {
              type
              passed {
                latitude
                longitude
                weight
                type
              }
            }
          }
        }
      }
    }
  }
}
   `;

export default (appId) => {

    let data = {};
    data.query = query;
    data.variables = {app_id: appId}

    return JSON.stringify(data);
}


