<template>

    <section class="home">
        <controls-component v-bind:app-selector="true" v-bind:date-selector="true" v-bind:reload-button="true"
                            v-bind:user-selector="true"></controls-component>
        <map-component></map-component>
        <Timeline></Timeline>
    </section>
</template>

<script>

    import Timeline from './Timeline';
    import ControlsComponent from './controls/ControlsComponent';
    import MapComponent from './MapComponent';

    export default {
        name: 'HomeComponent',
        components: {
            ControlsComponent,
            Timeline,
            MapComponent

        },

    };
</script>

<style lang="scss">
    .home {
        display: flex;
        flex-direction: column;

        .controls-bar {
            margin-bottom: 0;

            flex: 0 0 auto;
        }

        .map-component {
            > * {
                height: 100%;
            }
        }

        > *:not(.controls-bar) {
            flex: 0 1 100%;
        }
    }
</style>