
// root state object.
// each Vuex instance is just a single state tree.
import MUTATIONS from "../mutation-types";
import dayjs from 'dayjs';

export default  {
     state : {
        startTime: dayjs().subtract(7,'d').toDate(),
        endTime: dayjs().toDate()
    },

    getters: {
        startTime (state) {
            return dayjs(state.startTime).format('YYYY-MM-DD');
        },
        endTime (state) {
            return dayjs(state.endTime).format('YYYY-MM-DD');
        }
    },

   mutations : {
        [MUTATIONS.START_TIME] (state, time) {
            state.startTime = new Date(time);
        },
        [MUTATIONS.END_TIME] (state, time) {
            state.endTime = new Date(time);
        }
    },

     actions : {
        [MUTATIONS.START_TIME]: ({ commit }, time) => commit(MUTATIONS.START_TIME, time),
        [MUTATIONS.END_TIME]: ({ commit }, time) => commit(MUTATIONS.END_TIME, time),
    }
}

