// root state object.
// each Vuex instance is just a single state tree.
//import MUTATIONS from "../mutation-types";
import Api from '../../api/api';
import updateIntents from "../../api/updateIntents";
import ObjectUtil from "../../utils/objectUtil";

export default {

     state() {
         return {
             data: null,
             query: null,
             isLoading:false,
             load_settings: null
         }
    },

    namespaced: true,

    getters: {
         userData (state) {

             if(state.load_settings && state.data) {
                 return ObjectUtil.getDataByPath(state.data, state.load_settings.object_path);
             }
             else {
                 return state.data;
             }
         },
         isLoading (state) {
             return state.isLoading;
         }

     },

    mutations : {
        setData (state, data) {
            state.data = data;

        },
        query(state, query) {
            state.query = query;
        },
        isLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        load_settings (state, load_settings) {
            state.load_settings = load_settings;
        }


    },

    actions: { 
        'loadData': {
            root: true,
            handler(namespacedContext, loadIntent) {


                let start = namespacedContext.rootGetters.startTime;
                let end = namespacedContext.rootGetters.endTime;
                let app = namespacedContext.rootGetters.selectedApp;


                if(namespacedContext.state.load_settings) {

                    // check if this store wants to update
                    let settings = namespacedContext.state.load_settings;
                    let matchTheLoadIntent = ( (loadIntent && loadIntent===updateIntents.INIT) || settings.updateOn.indexOf(loadIntent) > -1);
                    console.log("X-QUERY match loadingitent", matchTheLoadIntent, loadIntent,settings.updateOn);
                    if( !matchTheLoadIntent) {
                        console.log("X-QUERY, STORE not interested in this loadIntent", settings.name, loadIntent, settings.updateOn);
                        return;
                    }

                    settings.start = start;
                    settings.end = end;
                    settings.app = app;
                    namespacedContext.commit('isLoading', true);
                    Api.getDataByCallParams(settings).then((data) => {
                        namespacedContext.commit('setData', data);
                        namespacedContext.commit('isLoading', false);
                    }).catch((error) => {
                        console.log("load error", error)

                        namespacedContext.commit('isLoading', false);
                    })
                }
            }
        },

        loadPrivate({state, commit, rootGetters}, queryParams) {


            let start = rootGetters.startTime;
            let end = rootGetters.endTime;
            let app = rootGetters.selectedApp;

            commit('isLoading', true);

            let settings = state.load_settings;
            settings.start = start;
            settings.end = end;
            settings.app = app;

            settings = Object.assign(settings,queryParams);

            Api.getDataByCallParams(settings).then( (users) => {
                commit('setData', users);
                commit('isLoading', false);
            }).catch( (error) => {
                console.log("load error", error)
                commit('isLoading', false);
            })
        }
    }
}

