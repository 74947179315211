<template>

    <div v-bind:title="title" v-bind:data-entry="entry"
         v-bind:style="style" class="entry-timeline-block">
        <span class="label">{{entry.label}}</span>
    </div>


</template>

<script>
    import dayjs from 'dayjs';

    export default {
        name: 'EntryTimelineBlock',
        props: {
            entry: Object,
            scale: Number,
            label: String,
            startdate: String,
        },
        computed: {
            style: function() {
                let diff = this.entry.end.diff(this.entry.start, 'minutes');
                let w = diff * this.scale;
                diff = this.entry.start.diff(dayjs(this.startdate), 'minutes');
                let l = diff * this.scale;
                return {
                    width: w + 'px',
                    left: l + 'px',
                };
            },
            title: function() {
                let t = this.entry.label + ' ';
                t += this.entry.start.format('DD.MM.YYYY HH:mm') + ' - ' + this.entry.end.format('DD.MM.YYYY HH:mm');
                return t;
            },
        },
    };
</script>

<style scoped>

    .days-timeline .entry-timeline-block {
        display: inline-block;
        position: absolute;
        height: 40px;
        border-right: 1px solid black;
        padding: auto;
    }

    .days-timeline .entry-timeline-block .label{
        display: inline;
    }

    .entry-timeline-block {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        height: 20px;
        border: 1px solid darkgray;
        margin: 0;
        font-size: 11px;
        font-weight: bold;
        color: #414141;
        background-color: rgba(1,1,1,0.2);
        overflow: hidden;
    }

    .entry-timeline-block .label{
        display: none;
        font-size: 11px;
    }


</style>