<template>
    <div class="user-selector field is-horizontal">
        <div class="field-label">
            <label class="label">User</label>
        </div>
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <v-select class="selector" multiple ref="userselector"
                              @input="setSelectedUser"
                              label="name"
                              :options="userlist"
                              :value="selectedUser"
                              placeholder="Select a user">
                        <template slot="option" slot-scope="option">
                            <div class="align">
                                <span class="option-style" :style="{ background:option.color }"></span>
                                <span>{{ option.name }}</span>
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import store from '@/store/index';
    import api from "@/store/modules/api";
    import vSelect from 'vue-select'
    import user_query from "@/api/queries/user_query";
    import getUserMetaById from '@/utils/userUtil';
    import ApiCallParamsVO from "../../vo/apiCallParamsVO";
    import updateIntents from "../../api/updateIntents";

    let apiCallParams = new ApiCallParamsVO();
    apiCallParams.query = user_query;
    apiCallParams.name="user-query";
    apiCallParams.object_path="data.app.active_users.slice";
    apiCallParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.TIME_RANGE_CHANGED, updateIntents.RELOAD_BUTTON];


    store.registerModule("user-store", api);
    store.commit('user-store/query', user_query);
    store.commit('user-store/load_settings', apiCallParams);

    export default {
        name: "user-selector",
        components: {vSelect},
        data() {
            return {
                selected: 0,
                userlistData: []
            }
        },
        methods: {
            setSelectedUser(val) {
                this.$store.commit('selectedUser', val)

            }
        },
        computed: {
            userlist() {
                return this.userlistData;
            },
            ...mapState({
                //data: state => state['user-store'].data,
                isLoading: state => state['user-store'].isLoading,
                selectedUser: state => state['selectedUser']
            }),
            ...mapGetters({
                userItems: 'user-store/userData'
            }),
        },
        watch: {
            isLoading: function (loading) {
                this.$refs.userselector.mutableLoading = loading;
            },

            userItems: function(data) {
                console.log("store update",data);
                if(data && Array.isArray(data)) {
                    this.userlistData = data.map((user) => { return getUserMetaById(user.id)});
                }
            }
        },
        mounted() {
            if(this.userItems && Array.isArray(this.userItems)) {
                this.userlistData = this.userItems.map((user) => { return getUserMetaById(user.id)});
            }

        }
    }
</script>

<style lang="scss">
    
    .user-selector {
        &, .align {
            display: flex;
            align-items: center;
        }
        
        .field-label {
            flex-grow: 0;
        }

        .v-select {
            background-color: #fff;
        }
        
        .searchable {
            border-radius: 4px;
        }

        .dropdown-toggle {
            border-color: transparent;
        }

        .v-select.open .dropdown-toggle {
            border-color: #5cb3fd;
        }

        .option-style {
            border-radius: 20px;
            width: 20px;
            height: 20px;
            display: inline-block;
            margin: 0 5px 0 -10px;
        }
    }
</style>