<template>
    <tr>
        <td>{{startDate }}</td>
        <td>{{endDate}}</td>
        <td>{{duration}}</td>
        <td>{{entry.type}}</td>
        <td>{{entry.analysis_type}}</td>
        <td>{{entry.meta.category}}</td>
        <td>{{entry.label}}</td>

        <!--<td v-for="(value, propertyName) in moment" :key="value">{{propertyName}} : {{value}}</td>-->
    </tr>
</template>

<script>
    import dayjs from 'dayjs';

    export default {
        name: 'EntryRow',
        props: {
            entry: Object
        },
        computed:{
            duration: function(){
                return this.entry.end.diff(this.entry.start, 'minutes')
            },
            startDate: function(){
                return dayjs(this.start).format('DD.MM.YYYY HH:mm:ss')
            },
            endDate: function(){
                return dayjs(this.end).format('DD.MM.YYYY HH:mm:ss')
            }
        }
    };
</script>

<style>
    table{
        border: 1px dotted gray;
        border-collapse: collapse;
    }

    td, th{
        padding: 1em;
        border: 1px dotted gray;
    }
</style>