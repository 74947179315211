
const query =`
    query ($app_id: String!, $from: String!, $to: String!) {
  app(id: $app_id) {
    id
    active_users {
      slice {
        id
        event_history(from: $from, to: $to) {
          type
          start
          end
          analysis_type
          ... on Stationary {
            duration
            latitude
            longitude
            location {
              significance
              place {
                type
                name
                category_hierarchy
              }
            }
          }
          ... on Transport {
            waypoints {
              type
              latitude
              longitude
              accuracy
            }
            mode
            distance
          }
        }
      }
    }
  }
}
`;

export default (appId, params) => {

  let {start, end} = params
  let data = {};
  data.query = query;
  data.variables = {
    app_id:appId,
      from: start,
      to: end
  }

  return JSON.stringify(data);
}


