import Vue from 'vue';
import App from './App.vue';
import {router} from './router/index';
import store from './store/index';

import fontawesome from '@fortawesome/fontawesome';
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import solid from '@fortawesome/fontawesome-free-solid';
import 'bulma/bulma.sass';

fontawesome.library.add(solid); // Use any icon from the Solid style

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')


