/**
 * Created by bscholl on 20.02.17.
 */
export default class MapHelper {

    static getColorPin(color) {
        color = color.replace('#','');
        var pin = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + color,
            new google.maps.Size(21, 34),
            new google.maps.Point(0,0),
            new google.maps.Point(10, 34));

        return pin;
    }

    static getSVGWithCharAndColor(char, color ) {
        return  {
            url: 'data:image/svg+xml;utf-8,<svg width="24" height="24" ' +
            'xmlns="http://www.w3.org/2000/svg">' +
            '<rect stroke="white" fill="'+color+'" x="1" y="1" width="22" ' +
            'height="22" /><text x="12" y="18" font-size="12pt" ' +
            'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
            'fill="white">'+char+'</text></svg>'

        }

    }

    static getPinTypeByLocation(location, color) {

        let pin = null;

        if (location && location.significance) {

            switch (location.significance) {
                case 'work':
                    pin = MapHelper.getSVGWithCharAndColor('W',color);
                    break;
                case 'home':


                    pin = {
                        path: "M218.278,103.775L118.384,7.497c-2.098-2.022-4.889-3.136-7.856-3.136c-2.969,0-5.76,1.113-7.857,3.136L2.776,103.775 c-3.346,3.224-2.955,5.996-2.425,7.312c0.531,1.315,2.115,3.605,6.761,3.605H18.36v85.57c0,8.906,7.676,16.43,16.368,16.43H85.36 v-58h49v58h51.965c8.72,0,16.035-7.678,16.035-16.43v-85.57h11.581c4.646,0,6.229-2.29,6.761-3.605 C221.232,109.771,221.624,107,218.278,103.775z",
                        fillColor: color,
                        fillOpacity: 1,
                        anchor: new google.maps.Point(120,120),
                        strokeWeight: 1,
                        strokeColor: color,
                        scale: 0.1
                    }

                    break;
                default:
                    // small dot
                    pin = {
                        //path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                        path: "M-15,0a15,15 0 1,0 30,0a15,15 0 1,0 -30,0",
                        fillColor: color,
                        fillOpacity: 0.2,
                        anchor: new google.maps.Point(0,0),
                        strokeWeight: 1,
                        strokeColor: color,
                        scale: 0.4
                    }

                    //pin = MapHelper.getColorPin(color);
                    break;
            }
        }

        else {
            pin = MapHelper.getColorPin(color);
        }

        return pin;
    }



}