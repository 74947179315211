<template>

    <div class="day-charts">
        <controls-component v-bind:app-selector="true" v-bind:date-selector="false" v-bind:reload-button="true"
                            v-bind:user-selector="false"></controls-component>
        <div class="columns is-multiline" v-bind:class="{ loading: isLoading }">
            <div class="column" v-for="(label, index) in labels" :key='index'>
                <div class="box" style="width: 650px;">
                    <h4 class="title">{{ label }}</h4>
                    <DayChart v-if="userData.length > 0" :userData="userData" :semanticTimeLabel="label"
                              width="600"></DayChart>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import DayChart from './DayChart'
    import ControlsComponent from '../controls/ControlsComponent'
    import store from '@/store/index';
    import api from "@/store/modules/api";
    import semantic_time_query from "../../api/queries/semantic_time_query";
    import ApiCallParamsVO from "../../vo/apiCallParamsVO";
    import updateIntents from "../../api/updateIntents";

    const modulename = 'daychart';

    let apiCallParams = new ApiCallParamsVO();
    apiCallParams.query = semantic_time_query;
    apiCallParams.name=modulename+"-query";
    apiCallParams.object_path="data.app.active_users.slice";
    apiCallParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.RELOAD_BUTTON];

    store.registerModule(modulename, api);
    store.commit(modulename + '/load_settings', apiCallParams);

    export default {
        name: "DayCharts",
        data() {
            return {
                labels: ['morning', 'lunch', 'afternoon', 'evening', 'night']
            }
        },
        components: {
            DayChart,
            ControlsComponent
        },
        computed: {
            ...mapState({
                //userData: state => state[modulename].data,
                isLoading: state => state[modulename].isLoading,
            }),
            ...mapGetters({
                userData: modulename+"/userData"
            })
        }
    }

</script>

<style lang="scss">
    .day-charts {
        
        > .columns {
            justify-content: center;
            
            > * {
                flex-grow: 0;
            }
        }
        
        
        .loading {
            opacity: 0.1;
        }
        
    }
</style>