<template>
    <div class="google-map" :id="mapName"></div>
</template>

<script>

    import GoogleMapController from "./GoogleMapController";

    export default {
        name: 'google-map',
        props: ['name'],
        data: function () {
            return {
                mapName: this.name + "-map",
            }
        },
        methods: {
            reset: function () {
                this.googleMap.reset()
            },
            resetHeatmap: function() {
                this.googleMap.resetHeatmap();
            },
            addPaths: function (locationList, groupName, pinColor) {
                this.googleMap.addPaths(locationList, groupName, pinColor)
            },
            addLocations: function (locationList, groupName, pinColor) {
                this.googleMap.addLocations(locationList, groupName, pinColor)
            },
            addHeatmapLayer: function (locationlist) {
                this.googleMap.addHeatmapLayer(locationlist)
            },
            showAllGroups: function () {
                this.googleMap.showAllGroups();
            },
            hideAllGroups: function () {
                this.googleMap.hideAllGroups();
            },
            showGroup: function (group) {
                this.googleMap.showGroup(group);
            }

        },
        mounted() {
            console.log("on mounted googlemap");
            const element = document.getElementById(this.mapName);
            this.googleMap = new GoogleMapController(element);
        }
    };
</script>

<style lang="scss">
    .google-map {
        min-width: 800px;
        min-height: 600px;
        background: gray;
    }
</style>