<template>
    <div v-if="userid">
        <h2>Timeline of events & moments</h2>

        <div class="moment-timeline-container">
            <EntryLane class="days-timeline timeline"
                       v-bind:entries="this.days"
                       v-bind:startdate="start"
                       v-bind:scale="this.timelineScale"></EntryLane>
            <EntryTimeline class="transport-timeline timeline"
                           v-bind:entries="this.transport"
                           v-bind:label="'Transport'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="stationary-timeline timeline"
                           v-bind:entries="this.stationary"
                           v-bind:label="'Stationary'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="activity-timeline timeline"
                           v-bind:entries="this.activity"
                           v-bind:label="'Activity'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="semantic-timeline timeline"
                           v-bind:entries="this.semantic_time"
                           v-bind:label="'Semantic Time'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="travel-timeline timeline"
                           v-bind:entries="this.travel"
                           v-bind:label="'Travel'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="geography-timeline timeline"
                           v-bind:entries="this.geography"
                           v-bind:label="'Geography'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="location-timeline timeline"
                           v-bind:entries="this.location"
                           v-bind:label="'Location'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryTimeline class="about-timeline timeline"
                           v-bind:entries="this.about_to_routine"
                           v-bind:label="'About to Routine'"
                           v-bind:startdate="start"
                           v-bind:scale="this.timelineScale"></EntryTimeline>
            <EntryLane class="days-timeline timeline"
                       v-bind:entries="this.days"
                       v-bind:startdate="start"
                       v-bind:scale="this.timelineScale"></EntryLane>

        </div>
        <!--<div class="entry-table">
            <h2>Moments</h2>
            <table>
                <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration (Minutes)</th>
                    <th>type</th>
                    <th>analysis_type</th>
                    <th>category</th>
                    <th>label</th>
                </tr>
                <EntryRow v-for="entry in this.entries" :key="entry.id" v-bind:entry="entry"></EntryRow>
            </table>
        </div>-->
    </div>
</template>


<script>

    import {mapState} from 'vuex';
    import dayjs from 'dayjs';
    import {EventEntry, Entry, MomentEntry} from './timeline/Entry';
    import EntryRow from './timeline/EntryRow';
    import EntryTimeline from './timeline/EntryTimeline';
    import EntryLane from './timeline/EntryLane';

    import query from '../api/queries/moment_event_history.gql.js';
    import store from '../store/index';
    import api from "../store/modules/api";
    import ApiCallParamsVO from "../vo/apiCallParamsVO";
    import updateIntents from "../api/updateIntents";

    let apiParams = new ApiCallParamsVO();
    apiParams.query = query;
    apiParams.object_path ='';
    apiParams.name='timeline-query';
    apiParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.TIME_RANGE_CHANGED, updateIntents.USER_SELECTION_CHANGED, updateIntents.RELOAD_BUTTON];

    store.registerModule("timeline", api);
    store.commit('timeline/load_settings', apiParams);


    export default {
        name: 'timeline',
        components: {
            EntryTimeline,
            EntryRow,
            EntryLane,
        },
        props: {
            dateformat: String,
        },
        data() {
            return {
                userData: {},
                window: {
                    width: 0,
                    height: 0,
                },
                userEntries: [],
            };
        },
        mounted() {
            window.addEventListener('load', () => {
                //this.fetchData();
            });
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
            window.removeEventListener('load', this.handleResize);
        },
        methods: {

            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },
            processData: function(result) {
                if (Array.isArray(result)) {
                    result = result[0];
                }


                try {
                    this.userData = result.data.user;
                }
                catch(e) {
                    console.log("TIMELINE did not find user data on result",result);
                    return;
                }

                let sentianceMoments = this.parseEntries(this.userData.moment_history, 'moment');
                let sentianceEvents = this.parseEntries(this.userData.event_history, 'event');
                this.userEntries = sentianceEvents.concat(sentianceMoments);

                this.userData.activity = this.userEntries.filter(
                    m => m.meta.category === 'activity');
                this.userData.semantic_time = this.userEntries.filter(
                    m => m.meta.category === 'semantic_time');
                this.userData.travel = this.userEntries.filter(
                    m => m.meta.category === 'travel');
                this.userData.geography = this.userEntries.filter(
                    m => m.meta.category === 'geography');
                this.userData.location = this.userEntries.filter(
                    m => m.meta.category === 'location');
                this.userData.about_to_routine = this.userEntries.filter(
                    m => m.meta.category === 'about_to_routine');
                this.userData.transport = this.userEntries.filter(
                    m => m.meta.category === 'transport');
                this.userData.stationary = this.userEntries.filter(
                    m => m.meta.category === 'stationary');
            },
            parseEntries: function(entries, type) {
                let parsedEntries = [];
                console.log('parse', entries);
                entries.forEach((e) => {

                    let entry;
                    if (type === 'moment') {
                        entry = MomentEntry.fromMoment(e);
                    } else if (type === 'event') {
                        if (e.type === 'Stationary') {
                            entry = EventEntry.fromStationary(e);
                        } else {
                            entry = EventEntry.fromTransport(e);
                        }
                    }

                    entry.id = Math.random();

                    if (entry.start.isBefore(dayjs(this.start))) {
                        console.log('reset start');
                        entry.start = this.start;
                    }
                    if (entry.end === null || !entry.end.isValid() || entry.end.isAfter(dayjs(this.end))) {
                        console.log('reset end');
                        entry.end = this.end;
                    }

                    if (entry.start.isBefore(dayjs(this.end))) {
                        parsedEntries.push(entry);
                    }

                });
                return parsedEntries;
            },
        },

        computed: {
            ...mapState({
                isLoading: state => state.timeline.isLoading,
                selectedUser: state => state.selectedUser,
            }),
            userid: function() {

                let user = '';
                if (this.selectedUser && this.selectedUser.length >= 1) {
                    user = this.selectedUser[0].id;
                }
                return user;
            },
            start: function() { return this.$store.getters.startTime;},
            end: function() { return this.$store.getters.endTime;},
            data() {
                return this.$store.state.timeline.data;
            },
            requestVars: function() {
                return {
                    'user_id': this.userid, // '56c2e4e2112e37101c000004',
                    'from': this.start,
                    'to': this.end,
                };
            },
            entries: function() {
                return this.userEntries ? this.userEntries : [];
            },
            activity: function() {
                return (this.userData && this.userData.activity) ? this.userData.activity: [];
            },
            semantic_time: function() {
                return (this.userData && this.userData.semantic_time) ? this.userData.semantic_time  : [];
            },
            travel: function() {
                return (this.userData && this.userData.travel) ? this.userData.travel  : [];
            },
            geography: function() {
                return (this.userData && this.userData.geography) ? this.userData.geography  : [];
            },
            location: function() {
                return (this.userData && this.userData.location) ? this.userData.location: [];
            },
            about_to_routine: function() {
                return (this.userData && this.userData.about_to_routine) ? this.userData.about_to_routine  : [];
            },
            transport: function() {
                return (this.userData && this.userData.transport) ? this.userData.transport : [];
            },
            stationary: function() {
                return (this.userData && this.userData.stationary) ? this.userData.stationary  : [];
            },
            timelineScale: function() {
                return (this.window.width / dayjs(this.end)
                    .diff(dayjs(this.start), 'minutes'));
            },
            days: function() {

                let duration = 24;
                let dateformat = 'DD.MM';
                if (this.window.width > 800) {
                    duration = 12;
                    dateformat = 'DD.MM HH:ss';
                }
                if (this.window.width > 1000) {
                    duration = 6;
                }

                let days = [];
                let start = dayjs(this.start);
                let end = dayjs(this.start)
                    .add(duration, 'h');

                while (end.isBefore(dayjs(this.end)) || end.isSame(dayjs(this.end))) {
                    let e = new Entry();
                    e.start = start.format();
                    e.end = end.format();
                    e.label = start.format(dateformat);
                    e.id = 'day' + start.format();
                    days.push(e);
                    start = start.add(duration, 'h');
                    end = end.add(duration, 'h');

                }
                return days;

            },

        },
        watch: {
            data: function(userData) {
                console.log('got date timeline', userData);
                this.processData(userData);
            },

            selectedUser: function(selectedUser) {

                console.log("on User change", selectedUser, this.userid);
                if(this.userid && this.userid != '' ) {
                    this.$store.dispatch('timeline/loadPrivate', {user_id:this.userid});
                }

            }
        },
    };
</script>

<style scoped>
    input,
    select {
        padding: 0.75em 0.5em;
        font-size: 100%;
        border: 1px solid #ccc;
        width: 100%;
    }

    .transport-timeline {
        background-color: rgba(67, 207, 211, 0.27);
    }

    .stationary-timeline {
        background-color: rgba(211, 109, 190, 0.27);
    }

    .activity-timeline {
        background-color: rgba(63, 211, 31, 0.27);
    }

    .semantic-timeline {
        background-color: rgba(211, 30, 39, 0.27);
    }

    .travel-timeline {
        background-color: rgba(52, 98, 211, 0.27);
    }

    .geography-timeline {
        background-color: rgba(211, 53, 188, 0.27);
    }

    .location-timeline {
        background-color: rgba(211, 210, 49, 0.27);
    }

    .about-timeline {
        background-color: rgba(35, 211, 205, 0.27);
    }

    .timeline {
        margin: 10px 0;
    }

    .days-timeline {
        height: 40px;
        background-color: lightgray;
    }

    .vdp-datepicker {
        display: inline-block;
    }
</style>