const query =
     `query ($user_id: String! $from: String! $to:String!) { 
                                  user(id: $user_id) { 
                                      id 
                                      moment_history(from: $from, to: $to) { 
                                          start 
                                          end 
                                          __typename 
                                          analysis_type 
                                          moment_definition_id 
                                          moment_definition { 
                                              type 
                                              id 
                                              category 
                                              display_name 
                                              description 
                                          } 
                                      }, 
                                      event_history(from: $from, to: $to) {
                                          type
                                          start
                                          end 
                                          analysis_type
                                          ... on Stationary {
                                            latitude
                                            longitude
                                            location {
                                              significance
                                            }
                                          }
                                          ... on Transport {
                                            mode
                                            distance
                                          }
                                      }
                                  }
                              }`


export default (appId, params) => {

    let data = {};
    data.query = query;
    data.variables = {//app_id:appId,
        from: params.start,
        to: params.end,
        user_id: params.user_id
    };

    if(params.user_id === null) {
        return null;
    }

    return JSON.stringify(data);
}


