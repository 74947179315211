<template>
    <div v-if="entries && entries.length > 0" class="entry-lane">
        <EntryTimelineBlock v-for="entry in entries"
                            :key="entry.id"
                            v-bind:entry="entry"
                            v-bind:startdate="startdate"
                            v-bind:scale="scale"></EntryTimelineBlock>
        <div class="lane-label" v-if="label">{{ label }} <span>({{entries.length}})</span></div>
    </div>
</template>

<script>
    import EntryTimelineBlock from './EntryTimelineBlock';


    export default {
        name: 'EntryLane',
        components: {
            EntryTimelineBlock,
        },
        props: {
            entries: Array,
            label: String,
            scale: Number,
            startdate: String,
        }
    };
</script>

<style scoped>
    .entry-lane {
        margin: 0;
        height: 20px;
        position: relative;
        border-bottom: 1px dotted gray;
    }

    .lane-label {
        display: inline-block;
        left: 0;
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
        color: #222222;
        padding: 3px 0 3px 3px;
    }
</style>