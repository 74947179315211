<template>
    <div class="date-selector field is-horizontal">
        <div class="field-label">
            <label class="label">Timerange</label>
        </div>
        <div class="field-body">
            <div class="field is-horizontal">
                <div class="control input">
                    <DatePicker id="startTime" v-bind:disabled-dates="disabledDates" v-on:selected="updateStartDate" v-bind:value="startTime" v-bind:key="startTime.toString()" v-bind:placeholder="'start date'" format="yyyy-MM-dd"></DatePicker>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="control input">
                    <DatePicker id="endTime" v-bind:disabled-dates="disabledEndDates" v-on:selected="updateEndTime" v-bind:value="endTime" v-bind:key="endTime.toString()" v-bind:placeholder="'end date'" format="yyyy-MM-dd"></DatePicker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vuejs-datepicker';
    import MUTATIONS from '@/store/mutation-types';
    import {mapState} from 'vuex'
    import updateIntents from "../../api/updateIntents";

    function onDatePickerChanged(store) {
        console.log("onDatePickerChanged");
        store.dispatch('loadData', updateIntents.TIME_RANGE_CHANGED);
    }

    export default {
        name: "date-selector",
        components: {DatePicker: DatePicker},

        computed: {
            disabledDates: function() {
                return {
                    from: this.$store.state.time.endTime || null
                }

            },
            disabledEndDates: function() {
                return {
                    to: this.$store.state.time.startTime || null
                }

            },
            ...mapState({
                startTime: state => state.time.startTime,
                endTime: state => state.time.endTime
            }),
        },
        methods: {

            updateStartDate: function(e) {
                let n = e;
                let o = this.$store.state.time.startTime;
                if(n.valueOf() !== o.valueOf()) {
                    this.$store.dispatch(MUTATIONS.START_TIME, n);
                    onDatePickerChanged(this.$store);
                }

            },
            updateEndTime: function(e) {
                let n = e;
                let o = this.$store.state.time.endTime;
                if(n.valueOf() !== o.valueOf()) {
                    this.$store.dispatch(MUTATIONS.END_TIME, n);
                    onDatePickerChanged(this.$store);
                }
            }

        }
    }
</script>

<style lang="scss">
    .date-selector {
        display: flex;
        align-items: center;
        
        .input, input {
            border: none;
            
            // hack for is-normal
            font-size: 1rem;
            vertical-align: middle;
            color: #363636;
        }
    }
</style>