export default {

    APP_MAP: {
        "spy": '5b053791592c22050000056b',
        "xmin": '5822274a2124350500000001',
    },
    DEFAULT_APP: "spy",
    API_URL: 'https://gkdczabaw1.execute-api.eu-central-1.amazonaws.com/dev/query',
    LOGIN_URL: 'https://gkdczabaw1.execute-api.eu-central-1.amazonaws.com/dev/login',

    TRANSPORT_MODES: [
        "car", //	When we have identified a transport mode as car.
        "walking",	//When we have identified a transport mode as walking.
        "biking", //When we have identified a transport mode as biking.
        "train", //When we have identified a transport mode as train.
        "bus", //When we have identified a transport mode as bus.
        "tram", //When we have identified a transport mode as tram.
        "subway", //When we have identified a transport mode as subway/underground/metro.
        "boat", //When we have identified a transport mode as boat.
        "motorcycle", //When we have identified a transport mode as motorcycle.
        "flight", //When we have identified a transport mode as flight. This is currently only available for processed results.
        "running", //When we have identified a transport mode as running. This is currently only available for preliminary results.
        "idle", //When a transport is identified as idle behavior.
        "other", //When a transport is identified as other transport mode.
        "insufficient_data", //When we don't have enough data to identify a transport mode.
    ]
}