<template>
    <div class="controls-bar navbar is-primary">
        <div class="navbar-start">
            <div class="navbar-item" v-if="showAppSelector">
                <app-selector></app-selector>
            </div>
            <div class="navbar-item" v-if="showDateSelector">
                <date-selector></date-selector>
            </div>
            <div class="navbar-item" v-if="showUserSelector">
                <user-selector></user-selector>
            </div>
        </div>
        <div class="navbar-end">
            <div class="navbar-item" v-if="showReloadButton">
                <load-button></load-button>
            </div>
        </div>
    </div>
</template>

<script>

    import AppSelector from './AppSelector';
    import UserSelector from './UserSelector';
    import LoadButton from './LoadButton';
    import DateSelector from './DateSelector';

    export default {
        name: 'HomeComponent',
        components: {
            DateSelector,
            UserSelector,
            LoadButton,
            AppSelector
        },

        props: {
            dateSelector: Boolean,
            userSelector: Boolean,
            appSelector: Boolean,
            reloadButton: Boolean
        },

        data: function () {
            return {
                showDateSelector: this.dateSelector,
                showUserSelector: this.userSelector,
                showAppSelector: this.appSelector,
                showReloadButton: this.reloadButton,
            }
        }

    };
</script>

<style lang="scss">
    .controls-bar {
        margin-bottom: 2rem;
        
        .navbar-start {
            // we need to break rows if components expand too much
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>