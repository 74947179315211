<template>
    <section class="segments-radar">
        <controls-component v-bind:app-selector="false" v-bind:date-selector="false" v-bind:reload-button="true" v-bind:user-selector="false"></controls-component>
        <div class="charts-wrapper">
            <div class="legend">
                <ul>
                   <li v-for="(app, index) in userGroups" v-bind:key="index"
                       :style="{'background-color': colors[index]}">
                       {{ app.name }}
                   </li>
                </ul>
            </div>

            <div class="charts">
                <radar-chart v-for="category in Object.keys(segmentDefinitions)" :key="category"
                             v-bind:id="category"
                             v-bind:title="category"
                             v-bind:user-groups="userGroups"
                             v-bind:category="category"
                             v-bind:zoom-axis="true"
                             v-bind:colors="colors"
                ></radar-chart>
            </div>
        </div>
    </section>
</template>

<script>
    import RadarChart from "./segmentcharts/RadarChart";
    import ControlsComponent from "./controls/ControlsComponent";
    import SunburstChart from "./segmentcharts/SunburstChart";
    
    import {mapState} from 'vuex'
    import store from '@/store/index';
    import apiSegments from "./segmentcharts/segmentsStore";
    import users_query from "../api/queries/active_users_query";
    import segments_query from "../api/queries/segment_definitions_query";
    import ApiCallParamsVO from "../vo/apiCallParamsVO";
    import updateIntents from "../api/updateIntents";
    
    const moduleSegments = "segmentRadarData";

    store.registerModule(moduleSegments, apiSegments);

    let appSettings = new ApiCallParamsVO();
    appSettings.object_path= '';
    appSettings.updateOn = [updateIntents.RELOAD_BUTTON];
    appSettings.name='app-query';
    appSettings.query = users_query;

    let segmentsSettings = new ApiCallParamsVO();
    segmentsSettings.object_path= '';
    segmentsSettings.updateOn = [updateIntents.RELOAD_BUTTON];
    segmentsSettings.name='segments-query';
    segmentsSettings.query = segments_query;


    store.commit(moduleSegments + '/appLoadSettings', appSettings);
    store.commit(moduleSegments + '/load_settings', segmentsSettings);
    
    export default {
        name: "Segments",
        components: {
            SunburstChart,
            RadarChart,
            ControlsComponent
        },
        data() {
            return {
                colors: ["#EDC951", "#CC333F", "#00A0B0"]
            }
        },
        computed: {
            ...mapState({
                userGroups: state => state[moduleSegments].appsData,
                segmentDefinitions: state => state[moduleSegments].segmentData,
                isLoading: state => state[moduleSegments].isLoading // TODO maybe a spinner?
            })
        }
    };
</script>

<style lang="scss">
    .segments-radar {
        .charts-wrapper {
            position: relative;

            > .legend {
                position: absolute;
                top: 2em;
                left: 2em;

                display: block;
                color: white;

                li {
                    padding: 2px 10px;

                    &.inactive {
                        opacity: 0.3;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            > .charts {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;

                > * {
                    flex: 0 1 615px;
                }
            }
        }
    }
</style>