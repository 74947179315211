import HeatMapComponent from '../components/HeatMapComponent';
import HomeComponent from '../components/HomeComponent';
import DayCharts from '../components/daycharts/DayCharts';
import SegmentsRadarComponent from '../components/SegmentsRadarComponent';
import SegmentsSunburstComponent from '../components/SegmentsSunburstComponent';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/Login';
import store from '@/store/index.js';

const routes = [

    {path: '/', redirect: '/home'},
    {path: '/home', name: 'Home', component: HomeComponent},
    {path: '/heatmap', name: "Heatmap", component: HeatMapComponent},
    {path: '/daycharts', name: 'DayCharts', component: DayCharts},
    {path: '/segmentsradar', name: 'Segments Radar', component: SegmentsRadarComponent},
    {path: '/segmentssunburst', name: 'Segments Sunburst', component: SegmentsSunburstComponent},
    {path: '/login', component: Login},
    {path: '/logout', 
        beforeEnter(to, from, next) { 
            store.dispatch('logout'); 
            next('/login');
        }
    }
];

const router = new VueRouter({
    routes,
    linkActiveClass: "is-active",

});

router.beforeEach((to, from, next) => {

    let loggedIn = localStorage.getItem('login');

    if (loggedIn) {
        next();
    }
    else {

        if (to.path !== "/login") {
            next({
                path: '/login'
            });
        }
        else {
            next();
        }

    }
});


Vue.use(VueRouter);

export {router, routes};
