<template>
    <div id="app">
        <Navigation :items="items"/>
        <main>
            <router-view></router-view>
        </main>
    </div>

</template>

<script>

import Navigation from './components/Navigation.vue'
import {routes} from './router/index';
import updateIntents from "./api/updateIntents";

export default {
  name: 'app',
  components: {
      Navigation,
  },
    data() {
      return {
        items : routes
      }
    },
  mounted() {

          this.$nextTick(function () {
              // initial load

              // save the login state to store
              if(localStorage.getItem('login')) {
                  this.$store.dispatch('login');
              }
              this.$store.dispatch('loadData', updateIntents.INIT);
          })
      }
}
</script>


<style lang="scss">
    html {
        width: 100%;
        height: 100%;
    }

    body {
        height: 100%;
        width: 100%;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        height: 100%;
        
        display: flex;
        flex-direction: column;
        
        nav {
            flex: 0 0 auto;
        }

        main {
            flex: 0 1 100%;
            
            > * {
                height: 100%;
            }
        }
    }
</style>
