export default class ApiCallParamsVO {

    constructor() {

        this.query = null;
        this.name = "";
        this.updateOn = [];
        this.object_path = "";
        this.start = null;
        this.end = null;
        this.app = null;
    }
}