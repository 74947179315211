<template>
    <section class="view columns is-mobile is-centered loginbox">

        <div class="column box is-two-fifths is-narrow ">

            <h1 class="is-size-3">Login</h1>
                <div class="field is-grouped">
                        <input  type="password" placeholder="password" @focus="hasError = false"  v-model="password" class="input" v-bind:class="{ 'is-danger': hasError}">
                        <button  type="submit" @click="login()" value="login" class="button is-link">Submit</button>

                </div>
        </div>
    </section>
</template>
<script>

    import constants from "../constants";
    import updateIntents from "../api/updateIntents";
    import LogUtil from "@/core/utils/logUtil"

    function callLogin(pw) {

        return fetch(constants.LOGIN_URL, {
            method: 'POST',
            body: JSON.stringify({password:pw}),
            headers: {'Content-Type': 'application/json'}
        }).then((response) => {

            if (response.ok) {
                return response.json()
            }
            else {
                LogUtil.log("RESPONSE ERROR resp", response);
                return response.json().then((json) => {
                    if (response.status == 401) {
                        return Promise.reject(new Error(JSON.stringify(json)));
                    }
                    else {
                        LogUtil.log("RESP ERROR", json);
                        return Promise.reject(new Error(JSON.stringify(json)));
                    }
                })
            }
        }).catch((error) => {

            LogUtil.log("RESPONSE ERROR", error)
            return Promise.reject(error);
        })

    }

    export default {
        name: 'login',
        data() {
            return {
                loading: false,
                hasError: false,
                password: ""
            }
        },
        methods: {
            login: function () {
                // should be replaced by real login code
                // there I just did some simple validation and use a fake login

                callLogin(this.password).then((result) => {

                    console.log(result);
                    localStorage.setItem('login', result.token);
                    // redirect to user page
                    this.$store.dispatch('login');
                    this.$store.dispatch('loadData', updateIntents.INIT);
                    this.$router.push('/')

                }).catch((error) => {
                    this.hasError = true;
                    console.log("login error", error);

                })

            }
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped  lang="scss">

    div.loginbox {
        margin-top: 40px;

        input ,button {

            margin: 10px;

        }

    }

</style>