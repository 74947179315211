const USER = {
    malte: {
        name: 'Malte',
        color: '#ff9326'
    },
    carl: {
        name: 'Carl',
            color: '#ff3714'
    },
    marco: {
        name: 'Marco',
            color: '#88ff8a'
    },
    hank: {
        name: 'Hank',
            color: '#120dff'
    },
    susanne: {
        name: 'Susanne',
            color: '#ff07e0'
    },
    björn: {
        name: 'Björn',
        color: '#a226ff'
    },
    jan: {
        name: 'Jan',
        color: '#cbff43'
    },
    martin: {
        name: 'Martin',
        color: '#444444'
    }
}

const USER_MAP = {

    '58eddd2a0a15fb060000012b': USER.malte,
    '5b054181fb4b0806000005a4': USER.malte,
    '59e7b3b2add0b6070000056b': USER.carl,
    '5b05696dac4e2e0500000682': USER.carl,
    '5a79c6b062eeed0500002038': USER.marco,
    '5b055a33ca6b87050000062b': USER.marco,
    '58493b52a94c8e0700000004': USER.hank,
    '5b05545a3097c0050000061b': USER.hank,
    '5b056b39a7d0390500000665': USER.susanne,
    '5b053e7bac4e2e0500000593': USER.björn,
    '5b055414ef291805000005f2': USER.jan,
    '5b0555cfa7d03905000005e9': USER.martin,

}


const getValueByUserId = (userId, key, fallback='') => {

    try {
        return USER_MAP[userId][key];
    }
    catch(e) {
        return fallback;
    }

}

export default (userId) => {

    return {
        id: userId,
        name: getValueByUserId(userId, 'name', userId),
        color: getValueByUserId(userId, 'color', null)
    }
}