<template>
    <div class="heat-map" v-bind:class="{ loading: isLoading }">
        <controls-component v-bind:app-selector="true" v-bind:date-selector="true" v-bind:reload-button="true"
                            v-bind:user-selector="true"></controls-component>
        <div class="map">
            <GoogleMap name="google_map_example" ref="googleheatmap"/>
        </div>
        <div class="toggles">
            <div class="tags has-addons" @click="toggleShowMode(mode)" v-for="mode in transportModes">
                <span class="tag">{{ mode }} ({{ modeCounter[mode] }})</span>
                <span :class="modeIsShown(mode) ? 'tag is-primary ' : 'tag'">{{ modeIsShown(mode) ? 'on' : 'off' }}</span>
            </div>
        </div>
    </div>

</template>


<script>
    import GoogleMap from '../components/map/GoogleMap';

    import ControlsComponent from './controls/ControlsComponent';
    import {mapState,mapGetters} from 'vuex'
    import constants from "../constants";


    import store from '../store/index';
    import api from '../store/modules/api';
    import ApiCallParamsVO from "../vo/apiCallParamsVO";
    import updateIntents from "../api/updateIntents";
    import default_query from '../api/queries/default_query';

    function filterChosenUsers(userDataArray, selectedUserIds) {
        if (selectedUserIds.length > 0) {
            let filteredUserArray = [];
            userDataArray.forEach(user => {
                if (selectedUserIds.indexOf(user.id) !== -1) {
                    filteredUserArray.push(user)
                }
            });
            return filteredUserArray;
        } else {
            return userDataArray
        }
    }

    let apiCallParams = new ApiCallParamsVO();
    apiCallParams.query = default_query;
    apiCallParams.name="heatmap_query";
    apiCallParams.object_path="data.app.active_users.slice";
    apiCallParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.TIME_RANGE_CHANGED, updateIntents.USER_SELECTION_CHANGED, updateIntents.RELOAD_BUTTON];

   // store.registerModule('map-store', api);
  //  store.commit('map-store/load_settings', apiCallParams);

    function getTransportModes() {
        const modes = constants.TRANSPORT_MODES.slice();
        modes.push('Stationary');
        return modes
    }



    export default {
        name: 'heatmap-view',
        components: {
            GoogleMap,
            ControlsComponent
        },
        data() {
            return {
                modeCounter: {},
                transportModes: getTransportModes(),
                shownModes: getTransportModes()
            }
        },
        computed: {
            ...mapState({
                isLoading: state => state['map-store'].isLoading,
                selectedUser: state => state.selectedUser
            }),
            ...mapGetters({
                data: 'map-store/userData',
            }),
            selectedUserIds() {
                let ids = [];
                console.log("selectedUserIds",this.selectedUser)
                if(Array.isArray(this.selectedUser)) {
                    this.selectedUser.forEach(user => {
                        ids.push(user.id)
                    });
                }
                return ids
            },
        },
        methods: {
            resetModeCounter() {
                console.log('reset mode counter...')
                this.modeCounter = getTransportModes().reduce((acc, val) => {
                    acc[val] = 0;
                    return acc
                }, {})
            },
            modeIsShown(mode) {
                let index = this.shownModes.indexOf(mode);
                return index !== -1;
            },
            toggleShowMode(mode) {
                let index = this.shownModes.indexOf(mode);
                if (index === -1) {
                    console.log('push', mode)
                    this.shownModes.push(mode)
                } else {
                    console.log('pop', mode)
                    this.shownModes.splice(index, 1);
                }
                console.log('will show', this.shownModes);
                this.resetModeCounter();
                this.loadComplete()
            },
            loadComplete() {

                console.log("HEATMAP CPOMPLETE");
                this.$refs.googleheatmap.resetHeatmap();
                let locations = [];
                let chosenUsers = filterChosenUsers(this.data, this.selectedUserIds);
                for (let user of chosenUsers) {

                    let user_points = user['event_history'] || [];

                    user_points.forEach(point => {
                        if (point['type'] === 'Stationary') {
                            this.modeCounter['Stationary'] += 1;
                            if (this.modeIsShown(point['type'])) {
                                locations.push({
                                    lat: point['latitude'],
                                    lon: point['longitude'],
                                    weight: 3
                                })
                            }
                        } else if (point['type'] === 'Transport') {
                            this.modeCounter[point['mode']] += 1;
                            if (this.modeIsShown(point['mode']) && point['waypoints']) {

                                let waypoints = point['waypoints'] || [];

                                waypoints.forEach(waypoint => {
                                    locations.push({
                                        lat: waypoint['latitude'],
                                        lon: waypoint['longitude'],
                                        weight: 2
                                    })
                                })
                            }
                        } else {
                            console.log('unknown point type', point['type'])
                        }
                    });
                }
                this.$refs.googleheatmap.addHeatmapLayer(locations)
            }
        },
        watch: {
            data: function (data) {
                console.log('heatmap, got the data', data);
                this.loadComplete();
            },
            selectedUser: function () {
                this.resetModeCounter();
                this.loadComplete();
            }
        },
        mounted() {
            this.resetModeCounter();
            if(this.data && this.data.length > 0) {
                this.loadComplete()
            }
        }
    };
</script>

<style lang="scss">
    .loading {
        opacity: 0.1;
    }
    .heat-map {
        display: flex;
        flex-direction: column;
        
        .loading {
            opacity: 0.1;
        }

        .controls-bar {
            margin-bottom: 0;
            
            flex: 0 0 auto;
        }
        
        .map {
            flex: 0 1 70%;
            
            .google-map {
                height: 100%;
            }
        }

        .toggles {
            flex: 0 0 auto;
            
            margin: 0 auto;
            padding: 2rem 0 2rem;
            transform: translateX(-9%); // make it appear more centered
            width: 800px; // decide items per row, together with item flex-basis (25% = 4)
            
            display: flex;
            flex-flow: wrap;
            
            > * {
                min-width: 180px;
                flex: 0 0 25%;
            }
            
            .tags {
                margin: 0; // override Bulma
                justify-content: flex-end;
            }
        }
    }
</style>