<template>
    <nav class="navbar is-dark" role="navigation">
        <div class="navbar-start">
             <router-link v-for="item in items" v-bind:key="item.name" class="navbar-item" v-if="item.name && loggedIn" :to="item.path">{{ item.name }}</router-link>
            <router-link class="navbar-item" v-if="!loggedIn" to="/login">Log in</router-link>
        </div>
        <div class="navbar-end">
            <router-link class="navbar-item" v-if="loggedIn" to="/logout">Logout</router-link>
        </div>

    </nav>


</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "navigation",
        props: {
            items: Array,

        },
        computed: {
            ...mapState({
                loggedIn: state => state.loggedIn
            })
        }
    }
</script>

<style lang="scss">

</style>