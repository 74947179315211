<template>
    <div :class="'chart radar ' + id">
        <h2>{{ title }}</h2>
        <div>

        </div>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    import Chart from './charts/radar';

    const margin = {top: 100, right: 100, bottom: 100, left: 100};
    const width = Math.min(700, window.innerWidth - 10) - margin.left - margin.right;
    const height = Math.min(width, window.innerHeight - margin.top - margin.bottom - 20);

    export default {
        name: "RadarChart",
        props: {
            id: String,
            title: String,
            userGroups: Array,
            category: String,
            usersKey: {default: "active_users"},
            dataAxisKey: {default: "display_name"},
            colors: {default: () => ["#EDC951", "#CC333F", "#00A0B0"]},
            zoomAxis: {default: false} // zoom chart to make highest value fill out axis, or false for comparable charts
        },
        data() {
            return {
                radarChartOptions: {
                    w: width,
                    h: height,
                    margin: margin,
                    maxValue: 1, // may change if this.zoomAxis = true
                    levels: 5,
                    roundStrokes: true,
                    color: d3.scaleOrdinal()
                        .range(this.colors)
                }
            }
        },
        mounted() {
            this.renderChart();  
        },
        methods: {
            renderChart() {
                let baseValueObj = this.getBaseChartValuesObject(this.userGroups, this.category);
                let valuesPerApp = this.getChartValues(this.userGroups, this.category, baseValueObj);
                let data = this.formatData(valuesPerApp, this.getAppNames(this.userGroups));
                
                Chart(".radar." + this.id, data, this.radarChartOptions)
            },
            formatData(valuesPerApp, appNames) {
                let data = [];
                let i = 0;
                for (let appValues of valuesPerApp) {
                    let appData = [];
                    for (let key in appValues) {
                        appData.push({
                            app: appNames[i],
                            axis: key,
                            value: appValues[key],
                        })
                    }
                    data.push(appData);
                    i++;
                }
                return data.length > 0 ? data : [[]];
            },
            getChartValues(userGroups, segmentCategory, baseValueObj) {
                let maxValue = 0;
                let groupValues = [];
                
                for (let userGroup of userGroups) {
                    let values = {...baseValueObj}; // shallow copy
                    let users = userGroup.active_users.slice;
                    
                    for (let user of users) {
                        if (maxValue < users.length) maxValue = users.length;
                        
                        for (let segment of user.segments) {
                            let s = segment.segment_definition;
                            
                            if (s.category === segmentCategory && values.hasOwnProperty([s[this.dataAxisKey]])) {
                                values[s[this.dataAxisKey]]++;
                            }
                        }
                    }
                    groupValues.push(values);
                }
                return this.normalizeChartValues(groupValues, maxValue);
            },
            normalizeChartValues(groupValues, maxValue) {
                let maxNorm = 0;
                for (let groupValue of groupValues) {
                    for (let category in groupValue) {
                        let normalised = groupValue[category] / maxValue;
                        groupValue[category] = parseFloat(normalised.toFixed(3));
                        
                        if (maxNorm < normalised) maxNorm = normalised;
                    }
                }
                if (this.zoomAxis) this.radarChartOptions.maxValue = maxNorm;
                return groupValues
            },
            getBaseChartValuesObject(userGroups, segmentCategory) {
                let valuesObj = {};
                for (let userGroup of userGroups) {
                    for (let user of userGroup.active_users.slice) {
                        for (let segment of user.segments) {
                            let s = segment.segment_definition;
                            if (s.category === segmentCategory) {
                                valuesObj[s[this.dataAxisKey]] = 0;
                            }
                        }
                    }
                }
                return valuesObj;
            },
            getAppNames(userGroups) {
                let names = [];
                for (let userGroup of userGroups) {
                    names.push(userGroup.name);
                }
                return names;
            }
        },
        watch: {
            userGroups: function () {
                this.renderChart()
            },
            category: function () {
                this.renderChart()
            },
            dataAxisKey: function () {
                this.renderChart()
            }
        },
        updated() {
            this.renderChart();
        }
    };
</script>

<style lang="scss">
    .chart.sunburst {
        h2 {
            margin-bottom: 0;
        }    
    }
</style>