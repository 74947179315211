
let consoleLog = function(type) {

    if (/log/.test(location.search) || window.log || _logEnabled) {

        let console = window.console || {};
        let logFn = console[type] || console.log || function() { };

        let apply = false;

        // Note: reading logFn.apply throws an error in IE11 in IE8 document mode.
        // The reason behind this is that console.log has type "object" in IE8...
        try {
            apply = !!logFn.apply;
        } catch (error) {}

        if (apply) {
            let args = [];

            for (let i = 1; i < arguments.length; i++) {
                args.push(formatError(arguments[i]));
            }

            return logFn.apply(console, args);
        }

        // we are IE which either doesn't have window.console => this is noop and we do nothing,
        // or we are IE where console.log doesn't have apply so we log at least first 2 args
        return function(type, arg1, arg2) {
            logFn(arg1, arg2 === null ? '' : arg2);
        };
    }

};



let formatError = function(arg) {

    if (arg instanceof Error) {
        if (arg.stack) {
            arg = (arg.message && arg.stack.indexOf(arg.message) === -1) ? 'Error: ' + arg.message + '\n' + arg.stack : arg.stack;
        } else if (arg.sourceURL) {
            arg = arg.message + '\n' + arg.sourceURL + ':' + arg.line;
        }
    }
    return arg;
};


let _logEnabled = true;


class LogUtil {

      static set enableLog(value) {
        _logEnabled = value;
    }


    static log() {
        /// #if LOGGING_ENABLED
        consoleLog('log', ...arguments);
        /// #endif
    }

    static logClass(instance, ...args) {
        /// #if LOGGING_ENABLED
        let className = (instance.constructor) ? instance.constructor.name : 'unknown';
        consoleLog('log','%c['+className+']%s','color:blue; font-weight: bold', '',  ...args);
        /// #endif
    }

    static info() {
        /// #if LOGGING_ENABLED
        consoleLog('info', ...arguments);
        /// #endif
    }

    static warn() {
        /// #if LOGGING_ENABLED
        consoleLog('warn', ...arguments);
        /// #endif
    }

    static error() {
        consoleLog('error', ...arguments);
    }

    static errorClass(instance, ...args) {
        let className = (instance.constructor) ? instance.constructor.name : 'unknown';
        consoleLog('error','%c['+className+']%s','color:red; font-weight: bold', '',  ...args);
    }
}


export default LogUtil;





