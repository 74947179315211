<template>
    <section class="segments-sunburst">
        <controls-component v-bind:app-selector="true" v-bind:date-selector="false" v-bind:reload-button="true" v-bind:user-selector="false"></controls-component>
        <div id="charts-wrapper">
            <div v-for="(userGroup, index) in userGroups" :key="index">
                <sunburst-chart
                        v-bind:id="'all-' + index"
                        v-bind:includeEmpty="true"
                        v-bind:title="userGroup.name + ': All Segments'"
                        v-bind:userGroup="userGroup.active_users.slice"
                        v-bind:segmentDefinitions="segmentDefinitions"
                ></sunburst-chart>

                <sunburst-chart
                        v-bind:id="'existing-' + index"
                        v-bind:includeEmpty="false"
                        v-bind:title="userGroup.name + ': Relative Segments'"
                        v-bind:userGroup="userGroup.active_users.slice"
                        v-bind:segmentDefinitions="segmentDefinitions"
                ></sunburst-chart>
            </div>
        </div>
    </section>
</template>

<script>
    import PostProcess from "./segmentcharts/helper/postProcess";
    
    import RadarChart from "./segmentcharts/RadarChart";
    import SunburstChart from "./segmentcharts/SunburstChart";
    import ControlsComponent from "./controls/ControlsComponent";

    import {mapState, mapGetters} from 'vuex'
    import store from '@/store/index';
    import api from '../store/modules/api';
    import users_query from "../api/queries/active_users_query";
    import segments_query from "../api/queries/segment_definitions_query";
    import updateIntents from "../api/updateIntents";
    import ApiCallParamsVO from "../vo/apiCallParamsVO";

    const moduleSegmentsUsers = "segmentSunburstUsersData";
    const moduleSegmentsDefinitions = "segmentSunburstDefinitionsData";

    store.registerModule(moduleSegmentsUsers, api);
    store.registerModule(moduleSegmentsDefinitions, api);

    let segmentsUsersParams = new ApiCallParamsVO();
    segmentsUsersParams.query = users_query;
    segmentsUsersParams.name="segments-users-query";
    segmentsUsersParams.object_path="data.app";
    segmentsUsersParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.RELOAD_BUTTON];

    store.commit(moduleSegmentsUsers + '/load_settings', segmentsUsersParams);

    let segmentsDefinitionsParams = new ApiCallParamsVO();
    segmentsDefinitionsParams.query = segments_query;
    segmentsDefinitionsParams.name="segments-definitions-query";
    segmentsDefinitionsParams.object_path="data.segment_definitions";
    segmentsDefinitionsParams.updateOn=[updateIntents.APP_SELECTION_CHANGED, updateIntents.RELOAD_BUTTON];

    store.commit(moduleSegmentsDefinitions + '/load_settings', segmentsDefinitionsParams);

    export default {
        name: "Segments",
        components: {
            SunburstChart,
            RadarChart,
            ControlsComponent
        },
        data() {
            return {
                userGroups:{},
                segmentDefinitions: {}
            }
        },
        computed: {
            ...mapState({
                isUsersLoading: state => state[moduleSegmentsUsers].isLoading,
                isDefinitionsLoading: state => state[moduleSegmentsDefinitions].isLoading // TODO maybe a spinner?
            }),
            ...mapGetters({
                data: moduleSegmentsUsers+'/userData',
                segmentData: moduleSegmentsDefinitions+'/userData'

            })

        },
        watch: {
            data: function (items) {
                this.userGroups = [items];
            },
            segmentData: function (items) {
                this.segmentDefinitions = PostProcess.segmentDefinitions(items);
            }
        },
        mounted() {
            let segmentData = this.segmentData;
            let data = this.data;
            if(segmentData) {
                this.segmentDefinitions = PostProcess.segmentDefinitions(segmentData);
            }
            if(data) {
                this.userGroups = [data];
            }
        }
    };
</script>

<style lang="scss">
    .segments-sunburst {
        .charts-wrapper {
            position: relative;
        }
    }
</style>